.wrapper {
    display: flex;
    align-items: center;
    padding: 6px 16px;
    cursor: pointer;

    &:hover {
        background: rgba(22, 24, 35, 0.03);
    }
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.info {
    flex: 1;
    margin-left: 12px;
}

.name {
    font-size: 1.6rem;
    font-weight: 500;
}

.check {
    margin-left: 6px;
    color: rgb(32, 213, 236);
}

.username {
    font-size: 1.4rem;
    color: rgba(22, 24, 35, 0.5);
}
