.wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    padding: 8px;
    border-radius: 4px;
    font-size: 1.6rem;
    font-weight: 700;
    // font-family: var(--font-family);
    cursor: pointer;
    background-color: var(--white);
    // border: 1px solid transparent;
    user-select: none;

    + .wrapper {
        // margin-left: 1.8rem;
        margin-left: 10px;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.icon + .title,
.title + .icon {
    margin-left: 8px;
}

.icon {
    display: inline-block;
    width: 24px;
    text-align: center;
}

// Button types
button,
a {
    &.user-menu-btn {
        height: var(--default-button-height);
        transition: var(--default-transition);
        font-size: 1.6rem;
        flex: 0 0 75px;

        span {
            font-weight: 700;
            // font-size: 1.8rem;
            font-size: 16px;
        }
        &.primary {
            flex: 0 0 90px;
        }
    }
    &.btn-no-padding {
        padding-right: 0;
        padding-left: 0;
        // font-size: 1.8rem;
        font-size: 16px;
        font-weight: 500;
        flex: 0 0 100px;
        span {
            width: 100%;
            display: inline-flex;
            justify-content: flex-end;
        }
    }
}

.text {
    color: var(--default-button-color);
    text-decoration: none;
    transition: var(--default-transition);
    &:hover {
        text-decoration: none;
    }
}

.rounded {
    border-radius: 999px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
    // border-color: rgba(22, 24, 35, 0.12);

    &:hover {
        border-color: rgba(22, 24, 35, 0.2);
        // background-color: rgba(22, 24, 35, 0.03);
        background-color: var(--light-blue-bg-1);
    }
}

.outline {
    color: var(--primary-dark-blue);
    border-color: currentColor;

    &:hover {
        border-color: currentColor;
        // background-color: rgba(254, 44, 85, 0.06);
    }
}

// Button sizes
.small {
    min-width: 115px;
    padding: 4px 16px;
}

.large {
    padding: 14px 16px;
    min-width: 140px;
}

.primary {
    color: var(--white);
    background-color: #69cbe1;
    // border-color: var(--primary-dark-blue);
    border-color: unset;
    &:hover {
        border-color: var(--primary-dark-blue);
        background: #36a9da;
    }
}

.primaryDark {
    color: var(--white);
    background-color: #36a9da !important;
    // border-color: var(--primary-dark-blue);
    border-color: unset;
    &:hover {
        background: #69cbe1 !important;
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .wrapper {
        margin-left: 10px;
        padding: 0;
        flex: 0 0 90px;
        min-width: unset;
        width: max-content;
        + .wrapper {
            margin-left: 10px;
        }
    }

    button,
    a {
        &.user-menu-btn {
            height: var(--default-button-height);
            transition: var(--default-transition);
            font-size: 16px;
            margin-left: 10px;
            &.primary {
                flex: 0 0 75px;
            }
            span {
                // font-weight: 700;
                font-size: 16px;
            }
            &.primary {
                flex: 0 0 90px;
            }
        }
        &.btn-no-padding {
            flex: 0 0 90px;
        }
    }
}
@media screen and (max-width: 767px) {
    .wrapper {
        // min-width: 97px;
        min-width: 115px;
    }
}
