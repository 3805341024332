.wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    max-width: 48px;
    width: 38px;
    padding: 9px 16px;
    border-radius: 4px;
    font-size: 1.6rem;
    font-weight: 700;
    font-family: var(--font-family);
    cursor: pointer;
    background-color: var(--white);
    user-select: none;

    + .wrapper {
        margin-left: 8px;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.icon + .title,
.title + .icon {
    margin-left: 8px;
}

.icon {
    display: inline-block;
    width: 18px;
    text-align: center;
}

// Button types
button.footer-icon-btn,
a.footer-icon-btn {
    height: var(--default-button-height);
    height: 48px;
    box-shadow: rgb(0 0 0 / 10%) 0 4px 4px;

    span {
        font-size: 2rem;
        font-weight: 500;
        width: 1.5rem;
        // height: 1.5rem;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        svg {
            width: 100%;
        }
    }
}
.text {
    height: var(--default-button-height);

    color: var(--default-button-color);
    text-decoration: none;
    transition: ar(--default-transition);
    &:hover {
        text-decoration: none;
    }
}

.rounded {
    height: var(--default-button-height);

    border-radius: 999px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
    border-color: rgba(22, 24, 35, 0.12);

    &:hover {
        border-color: rgba(22, 24, 35, 0.2);
        // background-color: rgba(22, 24, 35, 0.03);
        background-color: var(--light-blue-bg-1);
    }
}

.outline {
    height: var(--default-button-height);

    color: var(--primary-dark-blue);
    border-color: currentColor;

    &:hover {
        border-color: currentColor;
        // background-color: rgba(254, 44, 85, 0.06);
    }
}

// Button sizes
.small {
    min-width: 36px;
    padding: 4px;
    width: 36px;
    height: 36px;
}

.large {
    padding: 4px;
    min-width: 48px;
    width: 48px;
    height: 48px;
}

.primary {
    height: var(--default-button-height);

    color: var(--white);
    // background-color: var(--primary-dark-blue);
    background-color: #69cbe1;

    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), var(--primary);
    }
}
