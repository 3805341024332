.modal-body.modal-forgotpassword {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 10px 0;

    .title-wrapper {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }

    .fields {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;

        form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .tabs {
            padding: 5px 0;
            a {
                padding: 0 20px;
                cursor: pointer;

                &.active {
                    color: #0285ad;
                    text-decoration: underline;
                }
            }
        }

        .input {
            width: 100%;
            height: 50px;
            padding: 6px 12px;
            border-radius: 10px;
            border: 1px solid #dedede;
            box-shadow: inset 0 0 5px #dedede;
        }

        input {
            width: 100%;
            height: 100%;
            font-family: inherit;
            font-size: 1.6rem;
        }
    }

    .button-submit {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #69cbe1;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1.6rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        color: #fff;
        border-radius: 10px;
        max-width: 200px;
        margin-top: 20px;
        cursor: pointer;
        
        &:disabled {
            opacity: .65;
        }

        &:hover {
            background-color: #0285ad;
            color: #fff;
        }
    }
}

.password-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
        font-size: 16px;
    }
    .input {
        width: 100%;
        height: 50px;
        padding: 6px 12px;
        border-radius: 10px;
        border: 1px solid #dedede;
        box-shadow: inset 0 0 5px #dedede;
    }
}

.field-wrapper {
    margin-bottom: 20px;
    width: 100%;
}


.border-blue-remind-wrap {
    border: 2px solid #0084ab;
    background-color: #fff;
    color: #0084ab;
    font-size: 1.2rem;
    border-radius: 5px;
    font-weight: 700;
    padding: 0.1rem 0.375rem;
    margin-top: -8px;
}