.wrapper {
    width: 100%;
    // width: calc(100% - 74px);
    align-items: center;
    position: relative;
    // margin: auto;
    @media (max-width: 1250px) {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }
    @media (max-width: 576px) {
        width: 340px;
    }
}
.heading {
    // width: calc(100% - 74px);
    margin: auto;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 20px;

    @media (max-width: 1250px) {
        width: 100%;
        padding: 0px 25px;
    }
    span {
        &.icon {
            margin-right: 1rem;
            width: 32px;
            img {
                width: 100%;
                height: auto;
            }
        }
        &.text {
            // width: calc(100% - 150px - 32px);
            width: 100%;
        }
        h3 {
            font-size: 2.5rem;
        }
        &.read-more {
            width: 180px;
            a {
                font-weight: 600;
                font-size: 20px;
                text-align: right;
                color: #828282;
            }

            svg {
                margin-left: 7px;
            }
        }
    }
}
.article {
    // height: 100%;
    height: 272px;
    width: 306px;
    background-color: var(--white);
    border-radius: var(--default-article-radius);
    box-shadow: var(--default-article-box-shadow);
    padding: 25px 20px;
    display: flex;
    flex-direction: column;

    .header {
        height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;

        span {
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
            &.logo {
                width: calc(100% - 30px);
                // height: 100%;
                // height: 60px;
                a {
                    // height: 100%;
                    // height: 63px;
                    width: 100%;
                    // display: block;

                    img {
                        height: 60px;
                        // width: auto;
                        max-width: 180px;
                        // max-width: 100%;
                        object-fit: contain;
                    }
                }
            }
            &.favorite {
                width: 30px;
                height: 30px;

                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                a {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: var(--default-article-box-shadow);
                    border-radius: 4px;

                    img {
                        width: auto;
                        height: 100%;
                    }
                }
            }
            &.title {
                line-height: 2.2rem;
                width: 60%;
                a > h2 {
                    font-size: 18px;
                    line-height: 120%;
                    font-style: normal;
                    font-weight: 600;
                }
                h4 {
                    font-size: 1.4rem;
                    line-height: 120%;
                    font-style: normal;
                    font-weight: 400;
                    color: var(--sub-title-color);
                }
            }
            &.read-more {
                a {
                    font-size: 1.8rem;
                    font-style: normal;
                    font-weight: 400;
                    color: #828282;
                }
            }
        }
    }

    .content {
        // padding-top: 0.4rem;
        // padding-bottom: 1rem;
        height: 40px + 15px + 10px + 40px;
        .title {
            line-height: 2.2rem;
            width: 100%;
            a > h2 {
                font-size: 18px;
                line-height: 120%;

                font-style: normal;
                font-weight: 600;
                color: var(--default-article-title-color);
                // padding-bottom: 0.75rem;
                margin-bottom: 15px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-family: 'Noto Sans HK', 'Roboto';
            }
            h4 {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                color: var(--sub-title-color);
                width: 194px;
                line-height: 120%;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-family: 'Noto Sans HK', 'Roboto';
            }
        }
    }
    .footer {
        // margin-top: 0.75rem;
        color: var(--black);
        height: 100%;
        display: flex;
        align-items: flex-end;
        // item css of _tips, _helps
        .item {
            width: 100%;
            height: 23px;
            border-bottom: 1.5px solid rgba(154, 154, 154, 0.3);
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: 0;
            &:last-child {
                border-bottom: unset;
            }

            .count-job {
                display: flex;
                align-items: flex-end;
                font-size: 25px;
                height: 23px;
                line-height: 20px;
                width: 8rem;
                font-family: 'Roboto';
                font-weight: 400;
                p {
                    color: var(--black-violet);
                    font-size: 1.6rem;
                    line-height: 1.6rem;
                }
            }

            .count-job-number {
                padding-right: 0.3rem;
            }

            .tags {
                width: calc(100% - 8rem);
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                span {
                    height: 23px;
                    line-height: 22px;
                    background-color: var(--primary-dark-blue);
                    border-radius: 30px;
                    font-size: 16px;
                    width: 47px;
                    color: var(--white);
                    text-align: center;
                    &.yellow {
                        background-color: #ffc700;
                    }
                    &.orange {
                        background-color: #ff9f47;
                        width: 52px;
                    }
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 16px;
                        color: var(--white);
                    }
                }
                &.en {
                    span {
                        a {
                            font-size: 13px;
                        }

                        width: 70px;
                        &.orange {
                            width: 105px;
                        }
                    }
                }
            }

            span {
                &:last-child {
                    margin-right: 0;
                }
            }
            .thumbnail {
                width: 5.58rem;
                height: 5rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .item-title {
                width: 50%;
                h3 {
                    font-size: 1.33rem;
                    font-weight: 400;
                    line-height: 1.6rem;
                }
                p {
                    font-size: 1.2rem;
                    font-weight: 400;
                    line-height: 1.4rem;
                    margin-top: 0.25rem;
                    color: var(--sub-title-color);
                    &.sub-title {
                        color: var(--black-violet);
                    }
                }
            }
        }
    }
}

// mobile responsive
@media (min-width: 320px) and (max-width: 576px) {
    .heading {
        padding-right: 5px;
        padding-left: 5px;
        // margin-bottom: 1rem;
        width: 92vw;

        span {
            h3 {
                font-size: 2rem;
            }
            &.read-more {
                p {
                    font-size: 1.6rem;
                    font-weight: normal;
                }
            }
        }
    }

    .article {
        height: 242px;
        margin: 0;
    }
}
