@function homeCarouselWidth($containerWidth) {
    $standardContainerWidth: 1300px + 40px;
    $carouselWidth: 947px;
    @return calc($carouselWidth * ($containerWidth + 40px) / $standardContainerWidth);
}

@function homeCarouselHeight($containerWidth) {
    $standardContainerWidth: 1300px + 40px;

    $carouselWidth: 947px;
    $carouselHeight: 458px;

    @return calc($carouselHeight * ($containerWidth + 40px) / $standardContainerWidth);
}

@function homeBannerWidth($containerWidth) {
    $standardContainerWidth: 1300px + 40px;
    $carouselWidth: 947px;
    @return calc(($standardContainerWidth - $carouselWidth) * ($containerWidth + 40px) / $standardContainerWidth);
}

@function homeBannerItemHeight($containerWidth) {
    $standardContainerWidth: 1300px + 40px;
    $carouselWidth: 947px;
    $carouselHeight: 458px;

    @return calc((($carouselHeight * ($containerWidth + 40px) / $standardContainerWidth) - 30px) / 3);
}

.home-wrapper {
    width: 100%;
    .alice-carousel {
        background-color: red;
    }
}
.home-banners {
    width: 100%;
    padding-top: 40px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;

    font-size: 0.85rem;
}

.searchTextContainer {
    background-color: var(--search-top-banner-bg);
}

.carousel {
    // 1420 screen:
    // => width: 947px;
    // => height: 458px;
    width: homeCarouselWidth(var(--default-layout-width));
    height: homeCarouselHeight(var(--default-layout-width));
}
.banner {
    // 1440 screen => width: 393px;
    width: homeCarouselHeight(var(--default-layout-width));
    padding-left: 25px;
    .item {
        width: 100%;
        // height: calc((45.8rem - 3rem) / 3);
        height: homeBannerItemHeight(var(--default-layout-width));
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
// .inner {
//     margin-top: 15px;
// }
.view-all-btn {
    display: block;
    width: 33.6rem;
    margin: auto;
    text-align: center;
    padding-top: 90px;
    padding-bottom: 70px;
    a {
        width: 100%;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 2.9rem;
        text-align: center;
        transition: var(--default-transition);
        background-color: var(--primary);
        border-color: var(--primary);
        box-shadow: var(--default-article-box-shadow);
        // span {
        //     font-weight: 700;
        // }
    }
}
.logo-center-page {
    display: block;
    width: 33.6rem;
    margin: auto;
    text-align: center;
    position: relative;
    z-index: 10;
}
.featured-and-popular-module {
    // background-color: var(--white);
    background-color: #fffdfa;
    margin-top: -45px;
    margin-bottom: 70px;
    box-shadow: 2px -4px 20px rgba(130, 130, 130, 0.2);
    border-radius: 80px;
    padding: 0 40px;
    padding-bottom: 70px;
    width: 100%;
}

// responsive
@media (min-width: 320px) and (max-width: 575px) {
    .carousel {
        width: 100%;
        // height: 16.6rem;
        height: 100%;
        // margin-right: 25px;
        padding: 0 1.5rem;
        div {
            // height: 16.6rem;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .home-banners {
        height: 100%;
        padding-top: 0;
        margin-top: 2.5rem;
        margin-bottom: 4rem;
    }
    .featured-and-popular-module {
        border-radius: 30px;
        padding: 0 1.5rem;
        padding-bottom: 30px;
    }
    .view-all-btn {
        padding-top: 55px;
        padding-bottom: 30px;
    }
    .item {
        // width: 365px;
        width: calc(100vw - 30px);
        img {
            width: 100%;
            height: 100%;
        }
    }
    .filter {
        &.expand {
            height: 470px;
        }
    }

    // expand
    .filterFormContainer {
        padding: 20px 25px;
        // display: none;
        pointer-events: none;
        position: relative;
        z-index: -1;
        .collapseBtn {
            display: none;
        }
        &.expand {
            // display: inherit;
            z-index: 1;
            pointer-events: unset;
        }
    }
}
@media (min-width: 576px) and (max-width: 1023px) {
    .carousel {
        width: 100%;
        height: 100%;
        margin-right: 10px;
        padding: 0 1.5rem;
        div {
            // height: 16.6rem;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .home-banners {
        height: 100%;
        padding-top: 0;
        margin-top: 2.5rem;
        margin-bottom: 4rem;
    }
    .featured-and-popular-module {
        border-radius: 30px;
        padding: 0 1.5rem;
        padding-bottom: 30px;
    }
    .view-all-btn {
        padding-top: 55px;
        padding-bottom: 30px;
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .carousel {
        width: homeCarouselWidth(998px);
        height: homeCarouselHeight(998px);
    }
    .banner {
        // width: homeCarouselHeight(998px);

        .item {
            width: 100%;
            height: homeBannerItemHeight(998px);
        }
    }
}
@media (min-width: 1200px) and (max-width: 1439px) {
    .carousel {
        width: homeCarouselWidth(1150px);
        height: homeCarouselHeight(1150px);
    }
    .banner {
        // width: homeCarouselHeight(1150px);

        .item {
            height: homeBannerItemHeight(1150px);
        }
    }
}
@media (min-width: 1440px) and (max-width: 1535px) {
    .carousel {
        width: homeCarouselWidth(1350px);
        height: homeCarouselHeight(1350px);
    }
    .banner {
        // width: homeCarouselHeight(1350px);

        .item {
            height: homeBannerItemHeight(1350px);
        }
    }
}
@media (min-width: 1536px) {
    .carousel {
        width: homeCarouselWidth(1350px);
        height: homeCarouselHeight(1350px);
    }
    .banner {
        // width: homeCarouselHeight(1350px);

        .item {
            height: homeBannerItemHeight(1350px);
        }
    }
}

.filterFormContainer {
    padding: 30px var(--default-layout-horizontal-spacer) 0px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    pointer-events: none;
    // display: none;
    .collapseBtn {
        display: 'flex';
        margin: 25px auto 0px;
        justify-content: 'center';
        align-items: 'center';
        width: fit-content;
        svg {
            width: 34px;
        }
    }
    &.expand {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.6s;
        pointer-events: unset;
        // display: inherit;
    }
}
.filter {
    height: 0px;
    transition: height 0.6s;
    background-color: #e0f8fd;
    display: flex;
    &.expand {
        height: 248px;
    }
}
