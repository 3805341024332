.social-container {
    width: 100%;

    &>div {
        margin-bottom: 10px
    }

    .login-facebook {
        -webkit-box-flex: 1;
        flex: 1;
        margin-right: 10px;

        .social-button {
            background-color: #3b5998;
            color: #fff;
            width: 100%;
            border-radius: 10px;
            font-size: 16px;
            cursor: pointer;

            svg {
                margin-right: 10px;
            }
        }
    }

    .login-linkedin {
        -webkit-box-flex: 1;
        flex: 1;

        .social-button {
            background-color: #0077b5;
            color: #fff;
            width: 100%;
            border-radius: 10px;
            font-size: 16px;
            cursor: pointer;

            svg {
                margin-right: 10px;
            }
        }
    }

    .login-google {
        -webkit-box-flex: 1;
        flex: 1;
        margin-right: 10px;

        .social-button {
            border: 1px solid #efefef;
            display: -webkit-box;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            border-radius: 10px;
            font-size: 16px;
            cursor: pointer;

            img {
                max-width: 15px;
                object-fit: contain;
                margin-right: 10px;
            }
        }
    }

    .login-wechat {
        -webkit-box-flex: 1;
        flex: 1;

        .social-button {
            background-color: #7bb32e;
            color: #fff;
            width: 100%;
            border-radius: 10px;
            font-size: 16px;
            cursor: pointer;

            svg {
                margin-right: 10px;
            }
        }
    }


    .btn {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
}