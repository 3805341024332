.popup-modal {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    outline: 0;
    inset: 0;
    min-width: 500px;
    margin: 1rem;


    &.login,
    .signup {
        max-width: 500px;
    }
    @media (max-width: 767px) {
        min-width: 95vw;
        &.login,
        .signup {
            max-width: 80vw;
        }
    }
}
