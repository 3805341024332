.wrapper {
    --control-lang-default-height: 30px;
    --search-border-radius: 50px;
    --search-height: 48px;
    --search-top-spacer: 50px;
    --search-button-width: 90px;

    // position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: var(--white);
    &.expand-filter {
        justify-content: flex-start;
    }
}
.inner-wrapper {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    gap: 1.5rem;
}
.control-lang {
    width: 100%;
    height: var(--control-lang-default-height);
    // padding: 0 var(--default-layout-horizontal-spacer);
    display: flex;
    align-items: center;
    background-color: #69cbe1;
    // padding-left: calc(53px); // 60px - 10px translateX in container
    ul {
        // width: 90px;
        display: flex;
        list-style: none;
        li {
            border-right: 1px solid var(--line-color);
            &:last-child {
                border-right: none;
                a {
                    font-size: 0.7rem;
                }
            }
            a {
                padding: 3px;
                cursor: pointer;
                // font-family: 'Roboto';
                font-size: 1rem;
                font-weight: 600;
                width: 24px;
                height: 2px;
                line-height: 0px;
                display: flex;
                // border-right: 1px solid var(--line-color);
                text-align: center;
                align-items: center;
                justify-content: center;

                // &:last-child {
                //     border-right: none;
                // }
                &.active {
                    height: 4px;
                    line-height: 0;
                    max-width: 30px;
                    position: relative;
                    z-index: 1;
                    &::before {
                        position: absolute;
                        display: block;
                        content: '';
                        min-width: 16px;
                        max-width: 30px;
                        height: 16px;
                        background-color: #ebfff8;
                        top: calc(-50% - 2px);
                        left: calc(50% - 8px);
                        border-radius: 50px;
                        z-index: -1;
                    }
                }
            }
        }
    }
}

.inner {
    // height: var(--default-layout-menu-height);
    // width: var(--default-layout-width);
    padding: 0 var(--default-layout-horizontal-spacer);
    display: flex;
    align-items: center;
    justify-content: space-between;

    // padding-left: calc(60px); // 60px - 10px translateX in container
    // padding-right: 35px;

    padding-left: 6rem;
    padding-right: 3.5rem;

    z-index: 1;
    position: relative;
}

.logo-link {
    display: flex;
    // flex: 0 0 10.2rem;
    // width: 10.2rem;
    // height: 3.4rem;
    flex: 0 0 132px;
    width: 132px;
    height: 44px;
    img {
        width: 100%;
        height: auto;
    }
}

// Actions
.actions {
    display: flex;
    align-items: center;
    &.eng {
        button,
        a {
            font-family: 'Roboto';
            font-weight: 500;
            letter-spacing: normal;
        }
    }
}
.inner-btn {
    display: flex;
    align-items: center;
    button {
        border: 1.5px solid #36a9da;
        border-radius: 50px;
        // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    }
}
.loggedIn-btn {
    position: relative;
    display: flex;
    align-items: center;

    .newgig-btn {
        color: var(--primary-dark-blue);
        margin-right: 30px;
        padding: 0;
        min-width: auto;
    }
    .chat-btn,
    .noti-btn,
    .saved-btn {
        display: flex;
        align-items: center;
        margin-right: 18px;
        img {
            margin-right: 10px;
        }
    }

    .user-btn {
        display: flex;
        align-items: center;

        .user-avatar.name {
            border-radius: 50%;
            background-color: #f47602;
            -o-object-fit: cover;
            object-fit: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            position: relative;
            line-height: 1;
            text-transform: uppercase;
        }

        img {
            margin-left: 10px;
        }
    }

    a {
        white-space: pre;
    }

    .chevron {
        cursor: pointer;
    }
}

.more-btn {
    font-size: 2rem;
    margin-left: 28px;
    padding: 4px 8px;
    background-color: transparent;
    cursor: pointer;
}

.action-btn {
    position: relative;
    display: flex;
    font-size: 2.2rem;
    color: #161823;
    background-color: transparent;
    padding: 4px 10px;
    cursor: pointer;
}

.badge {
    position: absolute;
    top: -3px;
    right: 0px;
    padding: 0px 6px;
    height: 2rem;
    line-height: 2rem;
    border-radius: 10px;
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--white);
    font-family: var(--font-family);
    background-color: var(--primary);
}

.user-avatar {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 32px;
    &.en {
        margin-left: 22px;
    }
}

.user-submenu {
    position: absolute;
    right: 0;
    bottom: -10px;
    transform: translate(0, 100%);
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);

    .menu {
        width: 100%;
        background-color: #ffffff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 2px 4px 4px rgb(0 0 0 / 10%);
        padding-right: 20px;

        ul {
            padding-top: 7.5px;
            li {
                cursor: pointer;
                font-size: 1.6rem;
                width: 190px;
                padding: 7.5px 0 7.5px 20px;
                list-style-type: none;
                position: relative;
                align-items: center;

                a {
                    height: 30px;
                    display: flex;
                    align-items: center;
                }

                img {
                    opacity: 0.5;
                }

                .size-15 {
                    width: 35px;
                    // margin-right: 18px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 5px;
                    background-color: #ffffff;
                    height: 100%;
                    left: 0;
                    top: 0;
                }

                &:hover {
                    background-color: #fff3de;
                    border-radius: 0px 5px 5px 0px;

                    img {
                        opacity: 1;
                    }

                    &::before {
                        background: linear-gradient(88.31deg, #ffd465 4.99%, #ffc634 84.55%, #ffb800 98.84%);
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .logout-btn {
        cursor: pointer;
        text-align: center;
        padding: 7px 0;
    }
}
// responsive

// mobile device
@media (min-width: 320px) and (max-width: 575px) {
    .container {
        width: 320px;
    }
    body {
        font-size: 1.6rem;
    }
    .wrapper {
        // variable
        --mb-control-lang-menu-height: 3rem;
        --mb-short-menu-height: 5.7rem;
        --mb-toggle-baby-icon-width: 5.3rem;

        height: auto;
        position: relative;
    }

    .control-lang {
        padding-left: 25px;
        padding-right: 18px;
        height: 2.8rem;
        position: relative;
        z-index: 30;
        ul {
            // list-style: none;
            width: 60px;
            display: inline;
            li {
                a {
                    color: #ffffff;
                    background-color: var(--primary);
                }
            }
        }

        .loggedIn-btn {
            a {
                margin-right: 2px;
                &:last-child {
                    margin-right: 0;
                    img {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .home-button {
        padding-right: 1.7rem;
        width: 3rem;
        height: 2.8rem;
        line-height: 2.8rem;
        a {
            height: 100%;
            width: auto;
        }
        // display: inline-block;
    }
    // .mobile-control-lang {
    //     margin-top: 5.5px;
    //     width: 5rem;
    //     height: inherit;

    //     .dropdown {
    //         height: 1.7rem;
    //         display: flex;
    //         flex-direction: column;
    //         width: 5rem;
    //         background-color: var(--white);
    //         box-shadow: var(--default-article-btn-shadow);
    //         // border: var(--default-button-border-style);
    //         border-radius: 30px;
    //         // transition: var(--default-transition);
    //         transition: all ease-in 0.2s;
    //         .header {
    //             height: 1.7rem;
    //             height: 17px;
    //             display: flex;
    //             border-bottom: unset;
    //             span {
    //                 flex: 0 0 3.5rem;
    //             }
    //         }
    //         .body {
    //             display: flex;
    //             flex: 0 0 5rem;
    //             flex-direction: column;
    //             a {
    //                 border-bottom: 0.5px solid var(--grey);
    //                 height: 2rem;
    //                 color: #828282;
    //                 &:last-child {
    //                     border-bottom: none;
    //                 }
    //             }
    //         }
    //         &.open {
    //             height: 1.7rem + 2rem * 3;
    //             border-radius: unset;

    //             // remove background and shadow
    //             background: transparent;
    //             box-shadow: unset;
    //             .header {
    //                 border-radius: 5px;
    //                 background-color: #ffffff;
    //                 border-bottom: 1.5px solid var(--primary);
    //                 border-radius: 50px;
    //                 box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    //             }
    //             .body {
    //                 height: auto;
    //                 width: 50px;
    //                 border-radius: 5px;
    //                 background: #ffffff;
    //                 box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    //                 a {
    //                     height: 20px;
    //                     width: 45px;
    //                     padding-left: 11px;
    //                     margin-bottom: 5px;
    //                     border: none;

    //                     display: flex;
    //                     justify-content: flex-start;
    //                     align-items: center;

    //                     color: var(--primary);
    //                     font-size: 12px;

    //                     // for hover:
    //                     // :hover {
    //                     //     padding-left: 6px;
    //                     //     background-color: #fff3de;
    //                     //     border-radius: 0px 5px 5px 0px;
    //                     //     border-image-slice: 1;
    //                     //     border-image-source: linear-gradient(
    //                     //         88.31deg,
    //                     //         #ffd465 4.99%,
    //                     //         #ffc634 84.55%,
    //                     //         #ffb800 98.84%
    //                     //     );
    //                     //     transition: all ease 0.2s;
    //                     // }
    //                     &.active {
    //                         padding-left: 6px;
    //                         background-color: #fff3de;
    //                         border-radius: 0px 5px 5px 0px;
    //                         border-left: 5px solid;
    //                         border-image-slice: 1;
    //                         border-image-source: linear-gradient(
    //                             88.31deg,
    //                             #ffd465 4.99%,
    //                             #ffc634 84.55%,
    //                             #ffb800 98.84%
    //                         );
    //                         transition: all ease 0.2s;
    //                     }

    //                     &:first-child {
    //                         margin-top: 8px;
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     span,
    //     a {
    //         font-style: normal;
    //         font-weight: 700;
    //         font-size: 1.2rem;
    //         line-height: 1.4rem;

    //         justify-content: center;
    //         align-items: flex-start;
    //         padding: 1px;
    //         padding-left: 8px;
    //         color: var(--primary);
    //     }
    //     button.icon {
    //         display: flex;
    //         align-items: center;
    //         background-color: transparent;
    //         font-size: 8px;
    //         color: #828282;
    //     }
    // }
    .inner {
        display: block;
        height: var(--mb-short-menu-height);
        padding: 0 18px 0 25px;
    }
    .inner-wrapper {
        height: var(--mb-short-menu-height);
        justify-content: space-between !important;
        .icon {
            height: var(--mb-short-menu-height);
            line-height: var(--mb-short-menu-height);
            display: flex;
            align-items: center;
            button {
                height: 3rem;
                display: -webkit-inline-box;
                background: transparent;
                flex: 0 0 4rem;
            }
        }
        .logo {
            // flex: 0 0 12.3rem;
            // flex: 0 0 calc(100vw - 18rem - 4rem);
            height: 4rem;
            margin-left: 60px;
            div {
                width: 12.3rem;
                margin: auto;
                img {
                    max-width: 100%;
                    margin: auto;
                }
            }
        }
        button {
            // flex: 0 0 9rem;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            padding: 5px 7px;
        }
    }
    .toggle-menu {
        position: absolute;
        z-index: 100;
        margin: auto;
        display: flex;
        min-height: 50.3rem;
        width: 30rem;
        top: calc(var(--mb-control-lang-menu-height) + var(--mb-short-menu-height));
        left: var(--mb-toggle-baby-icon-width);

        transform: translateX(calc(-30rem - var(--mb-toggle-baby-icon-width)));
        transition: var(--default-transition);

        &__baby-icon {
            width: var(--mb-toggle-baby-icon-width);
            height: 6.2rem;
            height: 0;
            margin-left: -4.5rem;
            img {
                width: 100%;
            }
        }
        &__content {
            // padding: 2.5rem 2rem;

            // header
            .header {
                padding: 2.5rem 2rem 1rem 2rem;

                border-bottom: 1px solid #e0e0e0;
                .hello {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1.8rem;
                    // line-height: 2.1rem;
                    // margin-bottom: 2.5rem;
                }
                &__button {
                    display: flex;
                    justify-content: center;
                    margin: 20px 0;

                    button {
                        box-shadow: var(--default-article-box-shadow);
                        flex: 0 0 120px;
                        height: 37px;
                        padding: 4px 12px;
                        // border-width: 1.5px;
                        // border: 1.5px solid #36a9da;
                        border-radius: 50px;
                        // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

                        span {
                            // padding: 4px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            span {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 1.6rem;
                                line-height: 1.9rem;
                                margin-left: 0.8rem;
                                &.orange {
                                    color: var(--orange);
                                }
                                &.blue {
                                    color: var(--primary);
                                }
                            }
                        }
                    }
                }
            }

            // content
            .body {
                // padding: 0 2rem 2.5rem 2rem;
                padding: 0;
                // padding-bottom: 17px;

                // recommend-links
                .recommend-links,
                .usually-links {
                    padding: 0 0 20px 25px;
                    margin-top: 10px;
                    border-bottom: 1px solid #e0e0e0;
                    .title {
                        color: rgba(154, 154, 154, 0.9);
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 38px;
                        margin-bottom: 10px;
                    }
                    li {
                        display: flex;
                        list-style: none;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #37313c;
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            display: flex;
                            align-items: center;
                        }
                        svg {
                            margin-right: 13px;
                            width: 20px;
                        }
                    }
                    &:last-child {
                        border-bottom: unset;
                    }
                }
            }
        }
        section {
            width: 100%;
            height: 100%;
            position: relative;
            background-color: var(--white);
            background-color: #f9f9f9;
            /* Light Grey */

            border: 1px solid #f3f3f3;
            /* Small Icon Shadow */

            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
            border-radius: 0px 0px 10px 10px;
        }
        &.show {
            // @extend .toggle-menu;
            transform: translateX(0);
        }
    }
    .user-avatar {
        margin-left: 0;
    }
    .user-submenu {
        bottom: 0;
    }
}
@media (min-width: 576px) and (max-width: 1249px) {
    .container {
        width: 320px;
    }
    body {
        font-size: 1.6rem;
    }
    .wrapper {
        // variable
        --mb-control-lang-menu-height: 3rem;
        --mb-short-menu-height: 5.7rem;
        --mb-toggle-baby-icon-width: 5.3rem;

        height: auto;
        position: relative;
    }
    .control-lang {
        padding-left: 25px;
        padding-right: 18px;
        position: relative;
        z-index: 30;
        ul {
            // list-style: none;
            width: 60px;
            display: inline;
            li {
                a {
                    color: #ffffff;
                    background-color: var(--primary);
                }
            }
        }

        .loggedIn-btn {
            a {
                margin-right: 2px;
                &:last-child {
                    margin-right: 0;
                    img {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .home-button {
        padding-right: 1.7rem;
        width: 3rem;
        height: 2.8rem;
        line-height: 2.8rem;
        a {
            height: 100%;
            width: auto;
        }
        // display: inline-block;
    }
    // .mobile-control-lang {
    //     margin-top: 5.5px;
    //     width: 5rem;
    //     height: inherit;

    //     .dropdown {
    //         height: 1.7rem;
    //         display: flex;
    //         flex-direction: column;
    //         width: 5rem;
    //         background-color: var(--white);
    //         box-shadow: var(--default-article-btn-shadow);
    //         // border: var(--default-button-border-style);
    //         border-radius: 30px;
    //         // transition: var(--default-transition);
    //         transition: all ease-in 0.2s;
    //         .header {
    //             height: 1.7rem;
    //             height: 17px;
    //             display: flex;
    //             border-bottom: unset;
    //             span {
    //                 flex: 0 0 3.5rem;
    //             }
    //         }
    //         .body {
    //             display: flex;
    //             flex: 0 0 5rem;
    //             flex-direction: column;
    //             a {
    //                 border-bottom: 0.5px solid var(--grey);
    //                 height: 2rem;
    //                 color: #828282;
    //                 &:last-child {
    //                     border-bottom: none;
    //                 }
    //             }
    //         }
    //         &.open {
    //             height: 1.7rem + 2rem * 3;
    //             border-radius: unset;
    //             .header {
    //                 border-bottom: 1.5px solid var(--primary);
    //             }
    //         }
    //     }

    //     span,
    //     a {
    //         font-style: normal;
    //         font-weight: 700;
    //         font-size: 1.2rem;
    //         line-height: 1.4rem;

    //         justify-content: center;
    //         align-items: flex-start;
    //         padding: 1px;
    //         padding-left: 8px;
    //         color: var(--primary);
    //     }
    //     button.icon {
    //         display: flex;
    //         align-items: center;
    //         background-color: transparent;
    //         font-size: 8px;
    //         color: #828282;
    //     }
    // }
    .inner {
        display: block;
        // height: var(--mb-short-menu-height);
        padding: 0 18px 0 25px;
    }
    .inner-wrapper {
        height: var(--mb-short-menu-height);
        justify-content: space-between !important;

        .icon {
            // padding-left: 2rem;
            flex: 0 0 9rem;
            height: var(--mb-short-menu-height);
            line-height: var(--mb-short-menu-height);
            display: flex;
            align-items: center;
            // justify-content: center;

            button {
                height: 3rem;
                display: -webkit-inline-box;
                padding: 0;
                background: transparent;
                flex: 0 0 4rem;
            }
        }
        .logo {
            flex: 0 0 12.3rem;
            flex: 0 0 calc(100% - 18rem - 4rem);
            height: 4rem;
            div {
                width: 12.3rem;
                margin: auto;
                img {
                    max-width: 100%;
                    margin: auto;
                }
            }
        }
        button {
            flex: 0 0 9rem;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            padding: 5px 7px;
        }
    }
    .toggle-menu {
        position: absolute;
        z-index: 100;
        margin: auto;
        display: flex;
        min-height: 50.3rem;
        width: 30rem;
        top: calc(var(--mb-control-lang-menu-height) + var(--mb-short-menu-height));
        left: var(--mb-toggle-baby-icon-width);

        transform: translateX(calc(-30rem - var(--mb-toggle-baby-icon-width)));
        transition: var(--default-transition);

        &__baby-icon {
            width: var(--mb-toggle-baby-icon-width);
            height: 6.2rem;
            height: 0;
            margin-left: -4.5rem;
            img {
                width: 100%;
            }
        }
        &__content {
            // header
            .header {
                height: 13.9rem;
                padding: 2.5rem 2rem 0 2rem;

                border-bottom: 1px solid var(--grey);
                .hello {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1.8rem;
                    // line-height: 2.1rem;
                    // margin-bottom: 2.5rem;
                }
                &__button {
                    display: flex;
                    justify-content: center;
                    margin-top: 15px;
                    button {
                        box-shadow: var(--default-article-box-shadow);
                        flex: 0 0 12rem;
                        height: 37px;

                        span {
                            padding: 4px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            span {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 1.6rem;
                                line-height: 1.9rem;
                                margin-left: 1rem;
                                &.orange {
                                    color: var(--orange);
                                }
                                &.blue {
                                    color: var(--primary);
                                }
                            }
                        }
                    }
                }
            }

            // content
            .body {
                // padding: 0 2rem 2.5rem 2rem;
                padding: 0;
                // padding-bottom: 17px;

                // recommend-links
                .recommend-links,
                .usually-links {
                    padding: 0 0 20px 25px;
                    margin-top: 10px;
                    border-bottom: 1px solid #e0e0e0;
                    .title {
                        color: rgba(154, 154, 154, 0.9);
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 38px;
                        margin-bottom: 10px;
                    }
                    li {
                        display: flex;
                        list-style: none;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #37313c;
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            display: flex;
                            align-items: center;
                        }
                        svg {
                            margin-right: 13px;
                            width: 20px;
                        }
                    }
                    &:last-child {
                        border-bottom: unset;
                    }
                }
            }
        }
        section {
            width: 100%;
            height: 100%;
            position: relative;
            background-color: var(--white);
            background-color: #f9f9f9;
            /* Light Grey */

            border: 1px solid #f3f3f3;
            /* Small Icon Shadow */

            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
            border-radius: 0px 0px 10px 10px;
        }
        &.show {
            // @extend .toggle-menu;
            transform: translateX(0);
        }
    }

    // expand
    .filterFormContainer {
        padding: 20px 25px;
        // display: none;
        position: relative;
        z-index: -1;
        .collapseBtn {
            display: none;
        }
        &.expand {
            // display: inherit;
            z-index: 1;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1249px) {
    .control-lang {
        padding-left: 2.3rem;
    }
    .actions {
        flex: 0 0 calc(40% - 3rem);
        align-items: center;
        justify-content: flex-start;
        padding-right: 0;
    }
    .inner {
        padding-left: 2.5rem;
        padding-right: 35px;
    }
    .inner-btn {
        align-items: center;
        justify-content: end;
        flex: 0 0 100%;
        width: 100%;
    }
    .logo {
        height: 4rem;
        div {
            width: 12.3rem;
            margin: auto;
            img {
                max-width: 100%;
                margin: auto;
            }
        }
    }
    
}

@media (max-width: 1249px) {
    .user-avatar {
        margin-left: 0 !important;
    }

    .mobile-control-lang:hover {
        cursor: pointer;
    }

    .mobile-control-lang {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 10px;
        width: 5rem;

        .dropdown {
            height: 17px;
            display: flex;
            flex-direction: column;
            width: 50px;
            padding-right: 5px;
            background-color: var(--white);
            box-shadow: var(--default-article-btn-shadow);
            border-radius: 25px;
            transition: all ease-in 0.2s;
            .header {
                height: 100%;
                display: flex;
                align-items: center;
                span {
                    flex: 0 0 3.5rem;
                    white-space: nowrap;
                    font-size: 12px;
                }
            }
            .body {
                position: absolute;
                top: 25px;
                display: flex;
                flex: 0 0 5rem;
                flex-direction: column;
                a {
                    border-bottom: 0.5px solid var(--grey);
                    height: 2rem;
                    color: #828282;
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
            &.open {
                height: 17px;
                box-shadow: unset;
                .header {
                    // height: 30px;
                    border-radius: 25px;
                    background-color: #ffffff;
                    border-bottom: 1.5px solid var(--primary);
                    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
                }
                .body {
                    position: absolute;
                    height: auto;
                    width: 50px;
                    border-radius: 5px;
                    background: #ffffff;
                    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
                    a {
                        height: 20px;
                        width: 45px;
                        padding-left: 11px;
                        margin-bottom: 5px;
                        border: none;

                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        color: var(--primary);
                        font-size: 12px;
                        cursor: pointer;

                        &.active {
                            padding-left: 6px;
                            background-color: #fff3de;
                            border-radius: 0px 5px 5px 0px;
                            border-left: 5px solid;
                            border-image-slice: 1;
                            border-image-source: linear-gradient(
                                88.31deg,
                                #ffd465 4.99%,
                                #ffc634 84.55%,
                                #ffb800 98.84%
                            );
                            transition: all ease 0.2s;
                        }

                        &:first-child {
                            margin-top: 8px;
                        }
                    }
                }
            }
        }

        span,
        a {
            font-style: normal;
            font-weight: 700;
            font-size: 1.2rem;
            line-height: 1.4rem;

            justify-content: center;
            align-items: flex-start;
            padding: 1px;
            padding-left: 8px;
            color: var(--primary);
        }
        button.icon {
            display: flex;
            align-items: center;
            background-color: transparent;
            font-size: 8px;
            color: #828282;
        }
    }
}

@media (min-width: 1250px) and (max-width: 1439px)  {
    .control-lang {
        padding-left: 35px;
    }
}

