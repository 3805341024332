.embla__outer-container {
    height: 100%;
}

.embla {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    border-radius: 3rem;
    // margin-bottom: 8px;
}

.embla__viewport {
    overflow: hidden;
    width: 100%;
}

.embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
}

.embla__viewport.is-dragging {
    cursor: grabbing;
}

.embla__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.embla__slide {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 10px;
    overflow: hidden;
    border-radius: 3rem;
    aspect-ratio: 18.5 / 9;
}

.embla__slide__img {
    width: 100%;
    border-radius: 3rem;
    object-fit: cover;
    overflow: hidden;
    aspect-ratio: 18.5 / 9;
}

.embla__dots {
    display: flex;
    list-style: none;
    justify-content: center;
    margin-top: 20px;
    // background: rgb(223, 215, 215);
    position: relative;
    z-index: 1;
}

.embla__dot {
    cursor: pointer;
    position: relative;
    padding: 0;
    outline: 0;
    border: 0;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 7.5px;
    margin-left: 7.5px;
    display: flex;
    align-items: center;
}

.embla__dot:after {
    background-color: #8de3f7;
    width: 100%;
    height: 100%;
    content: '';
}

.embla__dot.is-selected:after {
    background-color: #36a9da;
    opacity: 1;
}

.embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0;
}

.embla__button:disabled {
    cursor: default;
    opacity: 0.3;
}

.embla__button__svg {
    width: 100%;
    height: 100%;
}

.embla__button--prev {
    left: 27px;
}

.embla__button--next {
    right: 27px;
}
