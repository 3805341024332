.menu-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // height: var(--default-layout-menu-height);
    height: 34px;
    width: 100%;
    // margin-left: 3.5rem;
    // flex: 0 0 calc(60% - 132px);
    // gap: 3.5rem;

    li {
        position: relative;
        width: fit-content;
        list-style: none;
        align-items: center;
        display: flex;
        // justify-content: center;
        justify-content: flex-start;
        height: 34px;
        margin-right: 3.5rem;
        font-size: 1.3rem;
        font-weight: 500;
        // flex: 0 0 120px;
        flex: 0 0 auto;
        a {
            font-size: 16px;
            letter-spacing: 1px;
        }

        &.en {
            a {
                letter-spacing: 0;
            }
        }
        &.has-badge {
            // margin-right: 60px;
            // margin-right: calc(54px + 18px);

            &:first-child {
                margin-right: 78px;
                &.en {
                    margin-right: 84px;
                }
            }
        }
        .badge {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(100%);
            padding: 0px 6px;
            height: 20px;
            line-height: 20px;

            width: max-content;
            // max-width: 54px;
            font-size: 13px;
            font-weight: 600;
            color: var(--white);
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
            // transform: matrix(1, 0, 0.01, 1, 0, 0);
            font-family: var(--font-family);
            letter-spacing: 0.05em;

            border-radius: 10px;
            background-color: var(--primary);
            &.green {
                background-color: rgba(99, 241, 190, 1);
            }
            &.yellow {
                background-color: #ffc700;
            }
            &.default {
                background-color: var(--primary);
            }
        }
    }
    &.eng {
        li {
            a {
                letter-spacing: unset;
                font-family: 'Roboto';
                // font-weight: 500;
            }
        }
    }
}
@media (min-width: 320px) and (max-width: 575px) {
    .menu-list {
        flex-direction: column;
        margin: 0;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
        gap: unset;

        li {
            margin: 0;
            line-height: 20px;
            font-size: 16px;
            height: 20px;
            margin-bottom: 10px;
            a {
                font-size: 16px;
                margin-right: 10px;
            }
            // .badge {
            //     top: -5px;
            //     left: 100%;
            // }
        }
    }
}
@media (min-width: 576px) and (max-width: 1249px) {
    .menu-list {
        flex-direction: column;
        margin: 0;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
        gap: unset;
        li {
            margin: 0;
            line-height: 20px;
            font-size: 16px;
            height: 20px;
            margin-bottom: 10px;
            a {
                font-size: 16px;
            }
            // .badge {
            //     top: -5px;
            //     left: 100%;
            // }
        }
    }
}
@media (min-width: 1024px) and (max-width: 1199px) {
    .menu-list {
        margin-left: 10px;
        flex: 0 0 calc(60% - 132px);
        // gap: 1.05rem;
        margin-left: 0;

        li {
            margin-right: 10px;
            // flex: 0 0 110px;
            flex: 0 0 auto;
            &.has-badge {
                // margin-right: 10px;
                margin-right: 0;

                &:first-child {
                    &.en {
                        margin-right: 54px;
                    }
                }
            }
            a {
                font-size: 13px;
                letter-spacing: 0;
            }
            .badge {
                // top: 0;
                // top: -7px;
                left: unset;
                right: 0px;
                font-size: 1.1rem;
            }
        }
    }
}
@media (min-width: 1200px) and (max-width: 1439px) {
    .menu-list {
        // margin-left: 10px;
        // flex: 0 0 calc(60% - 10.2rem);
        flex: 0 0 calc(60% - 132px);
        // gap: 3.5rem;
        margin-left: 0;

        li {
            margin-right: 10px;
            // flex: 0 0 110px;
            flex: 0 0 auto;
            &.has-badge {
                // margin-right: 10px;
                margin-right: 0;
            }
            a {
                font-size: 16px;
                letter-spacing: 0;
            }
            .badge {
                // top: 0;
                // top: -7px;
                left: unset;
                right: -8px;
                font-size: 1.1rem;
                font-size: 12px;
                // height: 20px;
                line-height: 20px;
            }
        }
    }
}
