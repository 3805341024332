.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 0.5rem;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
    font-size: 1.6rem;
    margin: 0;
    min-height: 50px;

    p {
        text-align: center;

        a {
            font-size: 1.6rem;
            color: white;
            font-weight: 600;
            text-decoration: underline;
            text-decoration-color: #fccf00;
            text-decoration-thickness: 2px;
            text-underline-offset: 5px;
        }
    }

    button {
        padding: 0.5rem 1rem;
        background: none;
        color: white;
        border: 1px solid white;
        border-radius: 0.6rem;
        margin: 0.5rem 1rem;
        cursor: pointer;
    }
}
