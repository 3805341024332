.wrapper {
    width: 100%;
    padding-top: 60px;
}
header {
    width: fit-content;
    // &.bg-yellow {
    //     background-color: var(--yellow);
    // }
    // &.bg-blue {
    //     background-color: var(--primary);
    // }
    // &.bg-default {
    //     background-color: var(--yellow);
    // }
    &.title {
        position: relative;
        margin: auto;
        text-align: center;
        height: 87px;
        line-height: 32px;
        padding: 0 0 45px 0;
        min-width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        svg {
            position: absolute;
        }
        h1 {
            position: absolute;
            font-size: 3rem;
        }

        img {
            max-width: 100vw;
        }
    }
}
._recommend {
    padding-top: 70px;
}
._hotJob {
    header {
        padding-bottom: 55px;
    }
}
._featured {
    padding-top: 70px;
}
._popular {
    padding-top: 110px;
}
@media (min-width: 320px) and (max-width: 576px) {
    header {
        &.title {
            width: auto;
            min-width: 0;
        }
    }
    .wrapper {
        padding-top: 55px;
    }
    header.title {
        height: 6rem;
        padding-bottom: 2.5rem;
    }
    ._recommend {
        padding-top: 0;
    }
}
