.phoneNumber-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .code-field {
        height: 50px;
    }

    .phone-field {
        width: 100%;
        height: 50px;
        input {
            width: 100%;
            padding: 6px 12px;
            border-radius: 10px;
            border: 1px solid #dedede;
            box-shadow: inset 0 0 5px #dedede;
        }
    }
}