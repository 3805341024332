.search,
.search-result {
    width: 561px;
}
.search {
    position: relative;
    height: var(--search-height);
    width: 100%;
    padding-left: 16px;
    display: flex;
    background-color: #ffffff;
    // border-radius: var(--search-border-radius);
    border-radius: 60px;
    border-color: var(--primary-dark-blue);
    border: var(--default-input-border-style);
    transition: var(--default-transition);
    // margin-top: var(--search-top-spacer);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    .search-icon {
        display: flex;
        align-items: center;
        padding-right: 12px;
        svg {
            color: var(--primary-dark-blue);
        }
    }

    form {
        flex: 1;
    }

    input {
        width: 100%;
        flex: 1;
        height: 100%;
        padding-right: 40px;
        color: var(--black);
        font-size: 16px;
        caret-color: var(--primary);
        background-color: transparent;
        font-family: var(--font-family);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    input::placeholder {
        color: rgba(154, 154, 154, 0.9);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.9rem;
    }
    input:not(:placeholder-shown) ~ .search-btn {
        color: rgba(22, 24, 35, 0.75);
    }

    &::after {
        content: '';
        position: absolute;
        top: var(--search-top-spacer);
        right: var(--search-button-width);
        width: 1px;
        height: calc(var(--search-height) - var(--search-top-spacer) * 2);
        background-color: rgba(22, 24, 35, 0.12);
    }

    &:focus-within {
        border-color: rgba(22, 24, 35, 0.2);
    }
}

.search-title {
    padding: 5px 12px;
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(22, 24, 35, 0.5);
}

.clear,
.loading {
    color: rgba(22, 24, 35, 0.34);
    background-color: unset;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
    from {
        transform: translateY(-50%) rotate(0);
    }
    to {
        transform: translateY(-50%) rotate(360deg);
    }
}

.filter-btn {
    background-color: transparent;
    flex-basis: 28px;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    border-right: 0.5px solid #e0e0e0;
}

.wrapper-search-btn {
    // width: var(--search-button-width);
    width: 105px;
    padding-left: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    .search-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 83px;
        height: 33px;
        padding: 0 16px;
        border-radius: var(--search-border-radius);
        font-size: 18px;
        color: var(--white);
        background-color: var(--yellow);
        text-shadow: var(--default-text-white-shadow);
        transition: var(--default-transition);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

        &:hover {
            cursor: pointer;
            background-color: var(--yellow-highlight);
        }

        &:active {
            background-color: var(--yellow-highlight);
        }
    }
}

@media (min-width: 320px) and (max-width: 576px) {
    .search {
        height: 4rem;
        justify-content: center;
        align-items: center;
        .search-icon {
            width: 1.8rem;
            height: 1.8rem;
            padding: 0;
            svg {
                color: var(--primary-dark-blue);
                width: 100%;
                height: 100%;
            }
        }
        input {
            color: rgba(154, 154, 154, 0.9);
            padding-right: 30px;
            overflow: hidden;
        }
        .wrapper-search-btn {
            // flex: 0 0 74px;
            width: 74px;
            padding-left: 0;
            height: 100%;
            border-left: 0.5px solid #e0e0e0;

            .search-btn {
                // flex: 0 0 63px;
                width: 63px;
                height: 29px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 1.6rem;
                line-height: 1.9rem;
                text-align: center;
                padding: 0;
            }
        }
    }
    .filter-btn {
        flex-basis: 21px;
        border-right: unset;
        svg {
            width: 14px;
            height: 14px;
        }
    }
}
