.wrapper {
    width: 100%;
    // width: calc(100% - 74px);
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    position: relative;
    // margin: auto;
    @media (max-width: 1250px) {
        width: 100%;
    }
    @media (max-width: 576px) {
        width: 340px;
    }
}
.heading {
    // width: calc(100% - 74px);
    margin: auto;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 20px;

    @media (max-width: 1250px) {
        width: 100%;
        padding: 0px 25px;
    }
    span {
        &.icon {
            margin-right: 1rem;
        }
        &.text {
            width: 100%;
        }
        h3 {
            font-size: 25px;
        }
        &.read-more {
            width: 180px;
            a {
                font-weight: 600;
                font-size: 20px;
                text-align: right;
                color: #828282;
            }
            svg {
                margin-left: 7px;
            }
        }
    }
}
.article {
    width: 306px;
    height: 272px;
    background-color: var(--white);
    border-radius: var(--default-article-radius);
    box-shadow: var(--default-article-box-shadow);
    > a {
        padding: 25px 20px;
        display: block;
        position: relative;
        height: 100%;
        border-radius: var(--default-article-radius);
    }
    .header {
        // height: 37px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 15px;

        span {
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
            &.logo {
                width: 75%;
                a {
                    height: 60px;
                    width: 100%;
                    display: block;
                    img {
                        height: 60px;
                        max-width: 180px;
                        object-fit: contain;
                    }
                }
            }
            &.favorite {
                width: 25%;
                height: auto;
                display: flex;
                text-align: center;
                justify-content: flex-end;
                align-items: center;
                margin-right: 6px;
                margin-top: 5px;
                position: relative;
                z-index: 10;
                a {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: var(--default-article-box-shadow);
                    border-radius: 4px;
                }
            }
            &.title {
                line-height: 1.95rem;
                width: 60%;
                h2 {
                    font-size: 1.42rem;
                    font-style: normal;
                    font-weight: 600;
                }
                h4 {
                    font-size: 1.24rem;
                    font-style: normal;
                    font-weight: 400;
                    color: var(--sub-title-color);
                    position: relative;
                    z-index: 10;
                }
            }
            &.read-more {
                a {
                    font-size: 1.42rem;
                    font-style: normal;
                    font-weight: 400;
                    color: #828282;
                }
            }
        }
    }

    .content {
        padding-top: 0;
        // margin-top: 1.3rem;
        // margin-top: 25px;
        .title {
            line-height: 1.95rem;
            width: 100%;
            h2 {
                color: var(--default-article-title-color);
                font-family: 'Roboto', 'Noto Sans HK';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                // line-height: 125%;
                width: 100%;
                height: 22px;

                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            h4 {
                color: var(--sub-title-color);
                width: auto;
                font-family: 'Roboto', 'Noto Sans HK';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 120%;
                margin-top: 10px;
            }
        }
        .description {
            margin-top: 10px;
            .item {
                label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    &:hover {
                        cursor: pointer;
                    }
                }
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                height: 15px;
                margin-bottom: 8px;

                svg {
                    // width: 20px;
                    width: 18px;
                    min-width: 18px;
                    margin-right: 8px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .footer {
        margin-top: 0.75rem;
        color: var(--black);
        position: absolute;
        bottom: 15px;
        right: 20px;

        .item {
            width: 100%;
            height: 23px;
        }
        .tags {
            width: 100%;
            height: inherit;
            display: flex;
            justify-content: flex-end;
            span {
                height: 23px;
                line-height: 22px; // padding: 2px 4px;
                width: 47px;
                background-color: var(--primary-dark-blue);
                border-radius: 30px;
                color: var(--white);
                text-align: center;
                &.yellow {
                    background-color: #ffc700;
                }
                &.orange {
                    background-color: #ff9f47;
                    width: 52px;
                }
                a {
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    color: var(--white);
                }
            }
            &.en {
                span {
                    a {
                        font-size: 13px;
                    }

                    width: 70px;
                    &.orange {
                        width: 105px;
                    }
                    a {
                        font-size: 13px;
                    }
                }
            }
        }
        display: flex;
        span {
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
        }
        .thumbnail {
            width: 5.58rem;
            height: 5rem;
            img {
                width: 100%;
                height: 100%;
            }
        }

        .item-title {
            width: 50%;
            h3 {
                font-size: 1.33rem;
                font-weight: 400;
                line-height: 1.6rem;
            }
            p {
                font-size: 1.2rem;
                font-weight: 400;
                line-height: 1.4rem;
                margin-top: 0.25rem;
                color: var(--sub-title-color);
                &.sub-title {
                    color: var(--black-violet);
                }
            }
        }
    }
}

// mobile responsive
@media (min-width: 320px) and (max-width: 576px) {
    .heading {
        padding-right: 5px;
        padding-left: 5px;
        // margin-bottom: 1rem;
        width: 92vw;

        span {
            svg {
                width: 25px;
                height: 25px;
            }
            h3 {
                font-size: 2rem;
            }
            &.read-more {
                // font-size: 1.6rem;

                p {
                    font-size: 1.6rem;
                    font-weight: normal;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    svg {
                        width: 9px;
                        height: 20px;
                    }
                }
            }
        }
    }
    .article {
        // height: 242px;
        margin: 0;
    }
}
