// @import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@100;300;400;500;700&family=Noto+Sans+SC:wght@300;400;500;700;900&family=Noto+Sans+TC&family=Roboto:wght@100;300;400;500&display=swap');

:root {
    // colors settings
    --primary: #69cbe1;
    --primary-dark-blue: #36a9da;
    --yellow: #f9c03e;
    --orange: rgba(255, 159, 71, 1);
    --yellow-highlight: #f5b010;
    --black: #313131;
    --black-violet: #37313c;
    --white: #fff;
    --grey: #e0e0e0;
    // --text-color: #161823;

    --read-more-color: #828282;
    --text-color: #37313c;
    --recommend-date-color: #a4a4a4;
    --sub-text-color: #3584a7;
    --sub-title-color: #9a9a9a;
    --support-text-color: rgba(154, 154, 154, 0.9);
    --line-color: rgba(154, 154, 154, 0.3);

    --light-blue-bg-1: rgba(105, 203, 225, 0.1);
    --light-blue-bg-2: rgba(236, 246, 248, 1);

    --search-top-banner-bg: rgba(105, 203, 225, 0.35);
    --bottom-banner-bg: rgba(215, 242, 251, 1);

    // font settings
    --font-family: 'Noto Sans HK', 'Noto Sans SC', 'Roboto', sans-serif;

    // Default layout
    --default-layout-header-height: 300px;
    --default-layout-menu-height: 70px;
    --default-layout-footer-height: 600px;

    // --default-layout-width: 1300px;
    --default-layout-width: 1350px;
    --default-layout-horizontal-spacer: 35px;

    // default input
    --default-input-border-color: var(--primary-dark-blue);
    --default-input-border-style: 2px solid var(--default-input-border-color);
    --default-input-height: 50px;
    --default-input-radius: 50px;

    // default button
    --default-button-color: var(--primary-dark-blue);
    --default-button-border-style: 1px solid var(--default-button-color);
    --default-button-height: 34px;
    --default-input-radius: 50px;
    --default-article-title-color: #3584a7;

    // Default animation
    --default-transition: all ease 0.3s;

    // Default text settings
    --default-text-white-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

    // default article style
    --default-article-box-shadow: 2px 2px 10px #00000020;
    --default-article-btn-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    --default-article-radius: 30px;
    --default-button-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

    --search-border-radius: 50px;
    --search-height: 48px;
    --search-top-spacer: 50px;
    --search-button-width: 90px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-size: calc(1vw * 0.75);
    // equivalent: 0.625 * 16 == 10px

    scroll-behavior: smooth;
}

body {
    font-family: var(--font-family);
    font-size: 1.8rem;
    line-height: 1.5;
    text-rendering: optimizespeed;
    color: var(--text-color);
    overflow-y: overlay;
    overflow-x: hidden;
}
a {
    font-size: 1.6rem;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}

// container, row layout
.container {
    width: var(--default-layout-width);
    height: inherit;
    // display: flex;
    display: block;
    // height: auto;
    align-items: inherit;
    justify-content: inherit;
    margin: auto;
    position: relative;
    &-fluid {
        width: 100vw;
    }
}
+ .container {
    height: inherit;
}
.row {
    width: 100%;
    height: 100%;
    display: flex;
}

.inner {
    padding: 0;
    width: 100%;
    // overflow: 'hidden';
}

.col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    -webkit-box-flex: 0;
}
.col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    -webkit-box-flex: 0;
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

a {
    color: var(--text-color);
    text-decoration: none;
}

.view-all-btn-style {
    a,
    button {
        height: 5.2rem;
    }
}
// bg color for wrapper-container >> default layout
.wrapper-container {
    width: 100%;
    overflow: hidden;
    background-color: var(--light-blue-bg-2);
}

// article-click-btn
a.article-click-btn {
    // min-width: 5.8rem;
    height: 26px;
    line-height: 19px;
    padding: 5px;
    font-size: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    // box-shadow: var(--default-article-btn-shadow);
    box-shadow: #0000001a 0 4px 4px;
    background-color: #69cbe1;
    border: unset;
    transition: var(--default-transition);
    &:hover {
        background-color: #36a9da;
    }
}
.recommend_single-item-article {
    padding-right: 2rem;
}
// override header
.header-module {
    .container {
        // margin-left: -10px;
        transform: translateX(0px);
    }
}
.header-control-lang {
    .container {
        // width: 100%;
        width: calc(var(--default-layout-width) + 25px);
    }
}
.header-inner {
    .container {
        // width: 100%;
        width: calc(var(--default-layout-width) + 25px);
    }
}
.menu-expand {
    .container {
        width: (calc(var(--default-layout-width) + 50px));
    }
}
.home-banner {
    .container {
        width: (calc(var(--default-layout-width) + 40px));
    }
}

// home-wrapper: customize css
.home-wrapper {
    // override class alice-carousel
    .home-banner {
        .alice-carousel {
            width: inherit;
            height: inherit;
            div {
                width: inherit;
                height: inherit;
                img {
                    width: inherit;
                    height: inherit;
                }
            }
            &__dots {
                margin: 0;
                line-height: 12px;
                height: 12px;
                position: absolute;
                left: calc(50% - 150px);
                width: 300px;
                bottom: 20px;
                &-item {
                    width: 11px;
                    height: 11px;
                    background-color: #d7f2fb;
                    &.__active {
                        background-color: var(--primary-dark-blue);
                    }
                }
            }
        }
    }

    // customize css for article
    .apply {
        background-color: white;
    }
    .wrapper-alice-carousel-optional-style {
        .alice-carousel {
            padding: 0 25px;
            position: relative;
            &__stage {
                &-item {
                    // add padding 1 rem top + bottom for showing shadow
                    padding: 10px 0;
                }
            }
        }
        .alice-carousel__prev-btn,
        .alice-carousel__next-btn {
            position: absolute;
            top: calc(50% - 30px);
            left: -20.5px;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            span {
                font-size: 30px;
            }
            .svg {
                width: 100%;
                height: 100%;
            }
        }
        .alice-carousel__next-btn {
            position: absolute;
            top: calc(50% - 30px);
            left: unset;
            right: -20.5px;
        }
    }
    .recommend-style {
        .alice-carousel {
            padding: 0 25px;
            position: relative;
            &__stage {
                &-item {
                    // add padding 1 rem top + bottom for showing shadow
                    padding: 10px 0;
                }
            }
            div.alice-carousel__wrapper {
                ul.alice-carousel__stage {
                    li.alice-carousel__stage-item {
                        width: 331px;
                        margin: 0 12.25px;
                    }
                }
            }
        }
        .alice-carousel__prev-btn,
        .alice-carousel__next-btn {
            position: absolute;
            top: calc(50% - 30px);
            left: -20.5px;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            span {
                font-size: 30px;
            }
            .svg {
                width: 100%;
                height: 100%;
            }
        }
        .alice-carousel__next-btn {
            position: absolute;
            top: calc(50% - 30px);
            left: unset;
            right: -20.5px;
        }
    }
}

.swiper {
    padding: 1rem 2.5rem;
    // width: 100%
    // overflow: 'visible'
}

@media (max-width: 576px) {
    .swiper {
        overflow: visible;
    }

    .swiper-wrapper {
        // .swiper-slide {
        //     width: 306px;
        //     height: 237px;
        //     &:last-child {
        //         margin-right: 0 !important;
        //     }
        //     @media (max-width: 575px) {
        //         width: auto;
        //     }
        // }
        width: 320px;
    }
    .jobSections-articles-carousel .hotJob-articles-carousel .recommend-articles-carousel .featured-articles-carousel {
        width: 320px;
        // padding-right: 10px;

        // display: flex;
        // justify-content: center;
    }
}

// // override swiper:
// .swiper-slide {
//     margin: 1rem 0rem 1rem 0rem;
// }

// // recommend-articles-carousel
// .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.recommend-articles-carousel {
//     position: unset;
//     padding: 10px;
//     .swiper-wrapper {
//         .swiper-slide {
//             width: 306px !important;
//             @media (min-width: 320px) and (max-width: 575px) {
//                 width: unset;
//             }
//             height: 237px;
//             &:last-child {
//                 margin-right: 0 !important;
//             }
//         }
//     }
//     @media (max-width: 1250px) {
//         padding: 10px 0px;
//     }
// }
.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
    position: unset;
    padding: 10px;
    display: flex;
    justify-content: center;
    // margin-left: 6%;

    .swiper-wrapper {
        .swiper-slide {
            width: 289px;
            display: flex;
            justify-content: center;
            // height: 242px;

            @media (max-width: 1250px) {
                width: 306px;
            }
        }
    }
    button {
        position: absolute;
        // width: calc(100% + 100px);
        width: 14px + 5px + 5px;
        top: calc(50% - 20px);
        z-index: 1;

        margin: 0 auto;
        left: -50px;
        // overflow: overlay;
        &.swiper-button-disabled {
            transition: all ease-out 0.2s;
            opacity: 0.5;
        }
    }
    button.swiper-next-button {
        top: calc(50% - 20px);
        left: unset;
        right: 0px - 10px - 14px - 5px;
    }
    button.swiper-prev-button {
        top: calc(50% - 20px);
        left: unset;
        left: 0px - 10px - 14px - 5px;
    }

    // &.jobSections-articles-carousel {
    //     width: 100%;
    // }
    &.hotJob-articles-carousel {
        width: 100%;
        // .swiper-wrapper {
        // .swiper-slide {
        // height: 237px;
        // }
        // }

        &.en {
            .swiper-wrapper {
                .swiper-slide {
                    height: 270px;
                }
            }
        }
    }
    // &.featured-articles-carousel {
    //     width: calc(293px * 4 + 20px * 3 + 10px * 2);
    //     @media (min-width: 576px) and (max-width: 767px) {
    //         width: calc(293px * 1.2 + 20px * 1 + 10px * 2);
    //     }
    //     @media (min-width: 768px) and (max-width: 1023px) {
    //         width: calc(293px * 2.2 + 20px * 2 + 10px * 2);
    //         width: auto;
    //         width: calc(100% - 10px * 2);
    //     }
    //     @media (min-width: 1024px) and (max-width: 1439px) {
    //         width: calc(293px * 3 + 20px * 2 + 10px * 2);
    //     }
    //     .swiper-wrapper {
    //         .swiper-slide {
    //             width: 293px;
    //             height: unset;
    //             &:last-child {
    //                 margin-right: 0 !important;
    //             }
    //         }
    //     }
    // }

    // @media (max-width: 1250px) {
    //     padding: 10px 0px;
    //     .swiper-next-button,
    //     .swiper-prev-button {
    //         display: none;
    //     }
    // }
}
// override the <HomeSection>
._jobsection,
._fulltime,
._parttime,
._shortterm {
    padding-top: 60px;
}
._featured {
    header.option-title {
        padding-bottom: 45px;
    }
}
._popular {
    padding-top: 110px;
    header.option-title {
        padding-bottom: 45px;
    }
}
// Fonts emmbeded
// @font-face {
//     font-family: ProximaNova;
//     src: url('/assets/fonts/Proxima-Nova-Regular.woff2');
//     font-weight: 400;
// }

// @font-face {
//     font-family: ProximaNova;
//     src: url('/assets/fonts/ProximaNova-Semibold.woff2');
//     font-weight: 600;
// }

// @font-face {
//     font-family: ProximaNova;
//     src: url('/assets/fonts/Proxima-Nova-Bold.woff');
//     font-weight: 700;
// }

// @font-face {
//     font-family: SofiaPro;
//     src: url('/assets/fonts/sofiapro-bold.woff');
//     font-weight: 700;
// }
// // noto sans HK
// @font-face {
//     font-family: Noto Sans HK;
//     src: url('/assets/fonts/noto-sans-hk/noto-sans-hk-v21-latin-300.woff');
//     font-weight: 300;
// }
// @font-face {
//     font-family: Noto Sans HK;
//     src: url('/assets/fonts/noto-sans-hk/noto-sans-hk-v21-latin-regular.woff');
//     font-weight: 400;
// }
// @font-face {
//     font-family: Noto Sans HK;
//     src: url('/assets/fonts/noto-sans-hk/noto-sans-hk-v21-latin-500.woff');
//     font-weight: 500;
// }
// @font-face {
//     font-family: Noto Sans HK;
//     src: url('/assets/fonts/noto-sans-hk/noto-sans-hk-v21-latin-700.woff');
//     font-weight: 700;
// }
// @font-face {
//     font-family: Noto Sans TC;
//     src: url('/assets/fonts/noto-sans-tc/NotoSansTC-Regular.otf');
//     font-weight: 400;
// }
// @font-face {
//     font-family: Noto Sans TC;
//     src: url('/assets/fonts/noto-sans-tc/NotoSansTC-Medium.otf');
//     font-weight: 500;
// }
// @font-face {
//     font-family: Noto Sans TC;
//     src: url('/assets/fonts/noto-sans-tc/NotoSansTC-Bold.otf');
//     font-weight: 700;
// }

// @font-face {
//     font-family: Roboto;
//     src: url('/assets/fonts/roboto/Roboto-Light.ttf');
//     font-weight: 300;
// }
// @font-face {
//     font-family: Roboto;
//     src: url('/assets/fonts/roboto/Roboto-Regular.ttf');
//     font-weight: 400;
// }
// @font-face {
//     font-family: Roboto;
//     src: url('/assets/fonts/roboto/Roboto-Medium.ttf');
//     font-weight: 500;
// }
// @font-face {
//     font-family: Roboto;
//     src: url('/assets/fonts/roboto/Roboto-Bold.ttf');
//     font-weight: 700;
// }

// Custom tippy tooltip
body {
    .tippy-box {
        border-radius: 8px;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.2rem;
        background-color: rgba(84, 84, 84, 0.92);
    }

    .tippy-arrow {
        color: rgba(84, 84, 84, 0.92);
    }

    .tippy-content {
        padding: 8px 9px;
    }

    .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
        top: -8px;
    }
}

.text-center {
    text-align: center;
}

// define for block / flex responsive
// .only-mobile__flex {
//     display: none;
//     visibility: hidden;
// }
// .only-mobile__block {
//     display: none;
//     visibility: hidden;
// }
// .only-pc__flex {
//     display: flex;
//     visibility: visible;
// }
// .only-pc__block {
//     display: block;
//     visibility: visible;
// }

.only-lg {
    display: inherit;
    visibility: visible;
}
.__w100 {
    width: 100%;
}
.__flex {
    display: flex;
}
.__block {
    display: block;
}
.__iblock {
    display: inline-block;
}
.__flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-center__row {
    flex-direction: row;
}
.flex-center__column {
    flex-direction: column;
}
.__d-none {
    display: none;
}
.__m-0 {
    margin: 0;
}
.only-xs {
    display: none;
    visibility: hidden;
}
.__flex-d-column {
    flex-direction: column;
}

/*
 * responsive
 */

// mobile device
@media (min-width: 320px) and (max-width: 575px) {
    .swiper-next-button,
    .swiper-prev-button {
        display: none;
    }
    html {
        font-size: 62.5%;
        overflow-x: hidden;
    }
    body {
        font-size: 1.6rem;
    }
    .container {
        // width: 320px;
        width: 100%;
    }
    .header-control-lang {
        .container {
            height: inherit;
            align-items: flex-start;
        }
    }
    .inner {
        padding: 0;
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .header-inner .container {
        width: 100%;
    }
    .menu-expand .container {
        width: 100%;
        // width: 100vw;
    }
    .home-banner .container {
        width: 100%;
    }
    // home wrapper: customize
    .home-wrapper {
        // override class alice-carousel
        .home-banner {
            .alice-carousel {
                // height: 16.6rem;
                height: 186px;
                &__dots {
                    margin: 0;
                    line-height: 12px;
                    height: 12px;
                    position: absolute;
                    left: calc(50% - 150px);
                    width: 300px;
                    bottom: 1rem;
                    z-index: 1;
                    &-item {
                        width: 11px;
                        height: 11px;
                        background-color: #d7f2fb;
                        // remove user select
                        -webkit-user-select: none; /* Safari */
                        -ms-user-select: none; /* IE 10 and IE 11 */
                        user-select: none; /* Standard syntax */
                        &.__active {
                            background-color: var(--primary-dark-blue);
                        }
                    }
                }
            }
        }
        .wrapper-alice-carousel-optional-style {
            .alice-carousel {
                padding: 0;
            }
        }
    }
    .copyright-optional {
        padding: 5rem 0;
        height: auto;
        .row-optional {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            p {
                padding-right: 0;
                width: 100%;
                margin: auto;
                justify-content: center;
            }
            span {
                margin: 0;
                width: auto;
                img {
                    width: 200px;
                }
            }
        }
    }

    .only-xs {
        display: inherit;
        visibility: visible;
    }
    .only-lg {
        display: none !important;
        visibility: hidden;
    }
    .__flex {
        display: flex;
    }
    .__block {
        display: block;
    }
    .__iblock {
        display: inline-block;
    }
    .__flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .__d-none {
        display: none;
    }
    .__w100 {
        width: 100%;
    }
    .__m-0 {
        margin: 0;
    }
}
@media (min-width: 576px) and (max-width: 1023px) {
    html {
        font-size: 62.5%;
        overflow-x: hidden;
    }
    body {
        font-size: 1.6rem;
    }
    .container {
        // width: 320px;
        width: 100%;
    }
    .header-control-lang {
        .container {
            height: inherit;
            align-items: flex-start;
        }
    }
    // .inner {
    //     padding-left: 15px;
    //     padding-right: 15px;
    // }

    .header-inner .container {
        width: 100%;
    }
    .menu-expand .container {
        width: 100%;
        // width: 100vw;
    }
    .home-banner .container {
        width: 100%;
    }
    // home wrapper: customize
    .home-wrapper {
        // override class alice-carousel
        .home-banner {
            .alice-carousel {
                height: inherit;
                &__dots {
                    margin: 0;
                    line-height: 12px;
                    height: 12px;
                    position: absolute;
                    left: calc(50% - 150px);
                    width: 300px;
                    bottom: 1rem;
                    z-index: 1;
                    &-item {
                        width: 11px;
                        height: 11px;
                        background-color: #ffffff;
                        &.__active {
                            background-color: var(--primary-dark-blue);
                        }
                    }
                }
            }
        }
        .wrapper-alice-carousel-optional-style {
            .alice-carousel {
                padding: 0;
            }
        }
    }

    .copyright-optional {
        padding: 5rem 0;
        height: auto;
        .row-optional {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            p {
                padding-right: 0;
                width: 100%;
                margin: auto;
                justify-content: center;
            }
            span {
                margin: 0;
                width: auto;
                img {
                    width: 200px;
                }
            }
        }
    }
    .only-xs {
        display: inherit;
        visibility: visible;
    }
    .only-lg {
        display: none !important;
        visibility: hidden;
    }
    .__flex {
        display: flex;
    }
    .__block {
        display: block;
    }
    .__iblock {
        display: inline-block;
    }
    .__flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .__d-none {
        display: none;
    }
    .__w100 {
        width: 100%;
    }
    .__m-0 {
        margin: 0;
    }
}

// modern laptop screen
@media (min-width: 1024px) and (max-width: 1199px) {
    html {
        // font-size: 50%;
    }
    .container {
        width: 998px;
        // width: 100%;
    }
    .header-control-lang {
        .container {
            width: 998px + 25px;
            width: 100%;
        }
    }
    .header-inner .container {
        width: 998px + 25px;
        width: 100%;
    }
    .home-banner {
        .container {
            width: 998px + 40px;
            // width: 100%;
        }
    }
    .menu-expand {
        .container {
            width: 998px + 50px;
            // width: 100%;
        }
    }
}

// full HD laptop screen
@media (min-width: 1200px) and (max-width: 1439px) {
    html {
        // font-size: 62.5%;
    }
    .container {
        width: 1150px;
        // width: 100%;
    }
    .header-control-lang {
        .container {
            width: 1150px + 25px;
            width: 100%;
        }
    }
    .header-inner .container {
        width: 1150px + 25px;
        width: 100%;
    }
    .home-banner {
        .container {
            width: 1150px + 40px;
            // width: 100%;
        }
    }
    .menu-expand {
        .container {
            width: 1150px + 50px;
            // width: 100%;
        }
    }
}

// high resolution or large screen laptop
@media (min-width: 1440px) and (max-width: 1535px) {
    html {
        // font-size: 75%;
        font-size: 10px;
    }
    .container {
        width: var(--default-layout-width);
    }
}
// lg screen
@media (min-width: 1536px) {
    html {
        // font-size: 75%;
        font-size: 10px;
    }
    .container {
        width: var(--default-layout-width);
    }
}

.help-block {
    padding-top: 5px;
    font-size: 1.2rem;
    color: #dd5126;
}

.text-dark-blue {
    color: #0085ab !important;
}

.cursor-pointer {
    cursor: pointer;
}

@media (min-width: 1250px) {
    .mobile-only {
        display: none !important;
    }
    
}

@media (max-width: 1249px) {
    .desktop-only {
        display: none !important;
    }
}