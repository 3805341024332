.password-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 6px 12px;
    border-radius: 10px;
    border: 1px solid #dedede;
    box-shadow: inset 0 0 5px #dedede;

    input {
        width: 100%;
        height: 100%;
    }

}