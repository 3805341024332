.wrapper {
    width: 100%;
    padding: 0 3rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    // padding: 0 2.2rem;
}

.popular-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 0 0 calc(100% / 3 - 10px);
    // margin-right: 1.5rem;
    // margin-bottom: 1.5rem;
    height: 10.4rem;
    padding: 25px 50px;
    background-color: var(--white);
    border-radius: 30px;
    box-shadow: var(--default-article-box-shadow);

    .icon-wrapper {
        img {
            object-fit: contain;
            max-height: 50px;
            min-height: 50px;
            min-width: 50px;
            max-width: 50px;
        }
        margin-right: 2.5rem;
    }

    &:nth-child(3n) {
        margin-right: 0;
    }
    svg {
        // margin-right: 2.5rem;
    }
    // heading
    span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.5rem;
        /* or 125% */
        color: var(--black-violet);
        letter-spacing: 1px;
    }
}

.view-all-btn {
    display: block;
    width: 33.6rem;
    margin: auto;
    text-align: center;
    padding-top: 45px;
    // padding-bottom: 55px;
    a,
    button {
        width: 100%;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 2.9rem;
        text-align: center;
        transition: var(--default-transition);
        // background-color: var(--primary);
        border-color: var(--primary);
        box-shadow: var(--default-button-box-shadow);
    }
}

@media (min-width: 320px) and (max-width: 575px) {
    .wrapper {
        padding: 0;
        gap: 14px;
        justify-content: space-around;
    }
    .popular-item {
        // padding: 2rem 2.5rem;
        padding: 2rem 2.5rem;
        flex: 0 0 calc(50% - 7px);
        width: calc(50% - 7px);
        // max-width: 163px;
        // flex: 1 0 auto;
        height: auto;
        margin: 0;
        gap: 1rem;
        aspect-ratio: 1 / 1;

        // for child
        flex-direction: column;
        align-content: center;
        justify-content: center;

        .icon-wrapper {
            margin: 0;
        }
        svg {
            margin: 0;
        }
        span {
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .view-all-btn {
        padding: 3rem 0 0 0;
    }
}
@media (min-width: 576px) and (max-width: 1023px) {
    .wrapper {
        padding: 0;
        gap: 1.4rem;
        justify-content: space-around;
    }
    .popular-item {
        padding: 2rem 2.5rem;
        flex: 0 0 calc(50% - 0.7rem);
        // flex: 1 0 auto;
        height: auto;
        margin: 0;
        gap: 1rem;
        aspect-ratio: 1 / 1;

        // for child
        flex-direction: column;
        align-content: center;
        justify-content: center;
        svg {
            margin: 0;
        }
        span {
            text-align: center;
        }
    }
    .view-all-btn {
        padding: 3rem 0 0 0;
    }
}
