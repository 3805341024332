.wrapper {
    --social-icon-width: 50px;
    --social-icon-height: 50px;

    // margin-top: 120px;
    position: relative;
    // z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    // min-height: var(--default-layout-footer-height);
    // height: var(--default-layout-footer-height);
    // height: 400px;
    background-color: var(--light-blue-bg-2);
    padding-top: 65px;
    // margin-top: 200px;
    overflow: hidden;
    .inner-background {
        width: 120%;
        margin: auto;
        margin-left: -10%;
        text-align: center;
        display: block;
        // height: calc(var(--default-layout-footer-height) - 120px);
        padding: 0 var(--default-layout-horizontal-spacer);
        padding-right: calc(10% + 24px);
        padding-left: calc(10% + 24px);
        padding-bottom: 1rem;
        background-repeat: no-repeat !important;
        background-size: 200% !important;
        background-position: center top !important;
        border-top-right-radius: 300px;
        border-top-left-radius: 300px;
        // padding: 0 24px;
    }
}

.content-footer {
    width: 100%;
    height: 100%;
    font-family: 'Noto Sans HK';
    .top-content {
        width: 100%;
        // height: 250px;
        height: inherit;
        margin-top: -65px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        span {
            display: flex;
            flex-direction: column;
            &.send-mail {
                // width: 50vw;
                padding: 0 3rem;
                flex: 0 0 45%;
                .inner {
                    width: 100%;

                    img {
                        width: auto;
                        height: 15rem;
                        margin: auto;
                    }
                    h6 {
                        font-weight: 400;
                        font-size: 1.3rem;
                        letter-spacing: 1px;
                        margin-top: 1rem;
                        text-align: center;
                    }
                    .form-wrapper {
                        // padding: 0 15px;
                        margin-top: 1rem;
                    }
                    a {
                        font-size: 1.3rem;
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }
            }
            &.follow {
                // width: 25vw;
                flex: 0 0 27.5%;
                align-items: center;
                .img-wrapper {
                    width: inherit;
                    img {
                        // width: fit-content;
                        width: auto;
                        height: 15rem;
                        transform: rotate(-3.24deg);
                    }
                }
            }
            &.chat {
                // width: 25vw;
                flex: 0 0 27.5%;
                align-items: center;
                .img-wrapper {
                    width: inherit;
                    img {
                        // width: fit-content;
                        width: auto;
                        height: 15rem;
                        transform: rotate(3.24deg);
                    }
                }
            }
        }
    }
}

.items {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    a {
        margin: 0 10px;
        transition: var(--default-transition);
    }
}
.links {
    display: flex;
    margin: 50px 0 0 0;
    vertical-align: middle;
    align-items: flex-start;
    gap: 80px;
    padding: 0 15px;
    padding-bottom: 2rem;

    // justify-content: center;
    ul {
        width: 155px;
        list-style: none;
        font-size: 16px;
        font-weight: 700;
        margin-top: 2rem;
        text-align: left;
        letter-spacing: 1px;
        &.last {
            width: 34vw;
        }
        li {
            font-weight: 400;
            display: flex;
            letter-spacing: 1px;
            margin-bottom: 20px;
            &:first-child {
                margin-top: 20px;
            }
            span {
                min-width: 30px;
                height: 30px;
            }
            a {
                white-space: pre-line;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}
.scroll-top-btn {
    position: fixed;
    bottom: 5rem;
    left: calc(50% + 1440px / 2 - 42px);
    z-index: 20;
    width: 6rem;
    height: auto;
    button {
        width: 100%;
        height: 100%;
        cursor: pointer;
        background-color: transparent;
        img {
            max-width: 100%;
        }
        transition: all ease-in 0.2s;
        &:hover {
            transform: scale(1.1);
            opacity: 0.9;
            transition: all ease-out 0.1s;
        }
    }
}

.copyright {
    width: 100%;
    height: 100px;
    background-color: #ffffff;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    line-height: 1.8rem;
    padding: 0 3rem;
    .text {
        width: 50%;
        text-align: left;
        display: flex;
        align-items: center;

        @media (min-width: 575px) {
            padding-left: 1.8rem;
        }
        @media (max-width: 1023px) {
            text-align: center;
        }
    }
    .logo {
        width: calc(50% + 95px);
        margin-left: -95px;
        img {
            width: 190px;
        }
    }
}

.en {
    font-family: 'Roboto';
    .item {
        a {
        }
    }
    .links {
        // justify-content: center;
        ul {
            li {
                a {
                    font-weight: 400;
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .scroll-top-btn {
        left: auto;
        right: 42px;
    }
}

// responsive
@media (min-width: 320px) and (max-width: 575px) {
    .wrapper {
        height: auto;
        min-height: unset;

        .inner-background {
            height: auto;
            width: 100%;
            margin: 0;
            border-top-right-radius: 50px;
            border-top-left-radius: 50px;
            padding: 0;
            .content-footer {
                height: auto;
            }
        }
    }
    .content-footer {
        .top-content {
            flex-direction: column;
            justify-content: center;
            align-content: center;
            span {
                &.send-mail {
                    padding: 0;
                    flex: 0 0 100%;
                    width: 100%;
                    margin-bottom: 2.5rem;
                }
                &.chat {
                    flex: 0 0 100%;
                    width: 100%;
                    margin-bottom: 2.5rem;
                }
                &.follow {
                    flex: 0 0 100%;
                    width: 100%;
                    margin-bottom: 2.5rem;
                }
            }
        }
    }
    .links {
        gap: 0;
        flex-direction: column;
        ul {
            flex: 0 0 100%;
            width: 100%;
            margin-top: 0;

            &:last-child {
                width: 100%;
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 575px) {
    .subscribe-declaimer {
        margin: 10px;
    }
}

@media (min-width: 576px) and (max-width: 1023px) {
    .wrapper {
        height: auto;
        min-height: unset;

        .inner-background {
            height: auto;
            width: 100%;
            margin: 0;
            border-top-right-radius: 50px;
            border-top-left-radius: 50px;
            padding: 0;
            .content-footer {
                height: auto;
            }
        }
    }
    .content-footer {
        .top-content {
            flex-direction: column;
            justify-content: center;
            align-content: center;
            span {
                &.send-mail {
                    padding: 0;
                    flex: 0 0 100%;
                    width: 100%;
                    margin-bottom: 2.5rem;
                }
                &.chat {
                    flex: 0 0 100%;
                    width: 100%;
                    margin-bottom: 2.5rem;
                }
                &.follow {
                    flex: 0 0 100%;
                    width: 100%;
                    margin-bottom: 2.5rem;
                }
            }
        }
    }
    .links {
        gap: 0;
        flex-direction: column;
        ul {
            flex: 0 0 100%;
            width: 100%;
            margin-top: 0;
            &:last-child {
                width: 100%;
            }
        }
    }
}
