.filter-inner {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
    font-size: 13px;
    &:last-child {
        margin-bottom: 0px;
    }
}
.filter-module {
    flex: 0 0 calc(100% / 3 - 20px);
    @media (min-width: 320px) and (max-width: 576px) {
        flex: 0 0 calc(100% - 20px);
    }
    & > div > div:nth-child(3) {
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
    }
}

.search,
.search-result {
    width: 561px;
}
.search {

    position: relative;
    height: var(--search-height);
    width: 100%;
    padding-left: 16px;
    display: flex;
    background-color: #ffffff;
    border-radius: var(--search-border-radius);
    border-color: var(--primary-dark-blue);
    border: var(--default-input-border-style);
    transition: var(--default-transition);
    // margin-top: var(--search-top-spacer);
    .search-icon {
        display: flex;
        align-items: center;
        padding-right: 12px;
        svg {
            color: var(--primary-dark-blue);
        }
    }
    input {
        flex: 1;
        height: 100%;
        padding-right: 40px;
        color: var(--black);
        font-size: 16px;
        caret-color: var(--primary);
        background-color: transparent;
        font-family: var(--font-family);
        border-right: 0.5px solid #e0e0e0;
    }

    input::placeholder {
        color: rgba(154, 154, 154, 0.9);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.9rem;
    }
    input:not(:placeholder-shown) ~ .search-btn {
        color: rgba(22, 24, 35, 0.75);
    }

    &::after {
        content: '';
        position: absolute;
        top: var(--search-top-spacer);
        right: var(--search-button-width);
        width: 1px;
        height: calc(var(--search-height) - var(--search-top-spacer) * 2);
        background-color: rgba(22, 24, 35, 0.12);
    }

    &:focus-within {
        border-color: rgba(22, 24, 35, 0.2);
    }
}

.search-title {
    padding: 5px 12px;
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(22, 24, 35, 0.5);
}

.clear,
.loading {
    position: absolute;
    right: calc(var(--search-button-width) + 8px);
    top: 50%;
    transform: translateY(-50%);
    color: rgba(22, 24, 35, 0.34);
    background-color: unset;
}

.loading {
    animation: spinner 0.8s linear infinite;
}

.roundCheckbox {
    position: relative;
    padding-left: 20px;

    span {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        height: 21px;
        left: 0;
        position: absolute;
        top: calc(50% - 10px);
        width: 21px;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    }

    span:after {
        border: 3px solid #fff;
        border-top: none;
        border-right: none;
        content: '';
        height: 3px;
        left: 4px;
        opacity: 0;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        width: 8px;
    }

    input[type='checkbox'] {
        visibility: hidden;
    }

    input[type='checkbox']:checked + span {
        background-color: #36a9da;
        border-color: #36a9da;
    }

    input[type='checkbox']:checked + span:after {
        opacity: 1;
    }
    &.selected {
        font-weight: bold;
    }
}
.dropdown {
    padding: 0px 0px 15px 0px;
    font-size: 13px;

    li {
        padding: 0px 24px;
        list-style: none;
        display: flex;
        align-items: center;
    }
    label {
        line-height: 30px;
        margin-right: 22px;
    }
    .spaceTop {
        height: 6px;
        width: 100%;
        display: block;
    }
    .selectedContent {
        padding: 5px 24px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 10;

        .selectedContentItem {
            border-bottom: 1px solid #c4c4c4;
            line-height: 26px;
            font-weight: 500;
            color: #000000;
            min-height: 35px;
            & > div {
                padding: 0px 5px 0px 10px;
                display: inline-block;
                background-color: #e0e0e0;
                border-radius: 5px;
                margin-right: 5px;
                margin-bottom: 5px;
                line-height: 25px;
                height: 25px;
                svg {
                    margin-left: 5px;
                    width: 7.5px;
                    height: 7.5px;
                    color: #fff;
                }
            }
            .descLabel {
                margin-top: 6px;
                font-family: 'Noto Sans HK';
                display: flex;
                justify-content: flex-start;
                color: #828282;
            }
            .warningLabel {
                font-family: 'Noto Sans HK';
                display: flex;
                justify-content: center;
                color: #ff9e12;
                font-size: 15px;
                margin-bottom: 5px;
            }
        }
    }
    .parentItem {
        &:hover {
            background-color: #deebff;
        }
        &.active {
            background-color: #fff3de;
        }
        .iconDropdown {
            flex-grow: 1;
        }
    }
}

.valueContainerCustom {
    display: flex;
    align-items: center;
}

.dropdownControlContainer {
    color: '#828282';
    display: 'flex';
    align-items: 'center';
    svg {
        margin-right: 8px;
    }
}

@keyframes spinner {
    from {
        transform: translateY(-50%) rotate(0);
    }
    to {
        transform: translateY(-50%) rotate(360deg);
    }
}

.salaryFilter-value-wrapper {
    color: #808080;
    position: relative;
    line-height: 32px;
    height: 32px;
}
.salaryFilter-currency-type {
    display: flex;
    align-items: center;
    svg {
        margin-right: 8px;
        &:first-child {
            color: #808080;
        }
        &:last-child {
            margin-left: 8px;
        }
    }
}
.salaryFilter-wrapper {
    width: 100%;
    font-weight: 500;
    cursor: pointer;
}
.salaryFilter-value {
    background: #ffffff;
    border: 1.5px solid rgba(196, 196, 196, 0.9);
    border-radius: 20px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
    display: flex;
    gap: 8px;
    padding-inline: 21px;
}
.salary-type-select {
    position: absolute;
    top: calc(100% + 8px);
    left: 0px;
    width: 100%;
    background: #ffffff;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    z-index: 1000;
}
.salary-labels {
    display: flex;
    background: #fff;
    border: 1px solid #36a9da;
    width: max-content;
    height: 35px;
    border-radius: 10px;
    margin-bottom: 24px;
    font-size: 15px;
}
.salary-labels div {
    margin-top: -1px;
    margin-right: -1px;
    border-radius: 10px;
    width: 85px;
    height: 35px;
    text-align: center;
    line-height: 34px;
    color: #828282;
}
.salary-labels div.active {
    background: #36a9da;
    color: white;
}
.month-labels,
.hour-labels {
    width: 100%;
    display: flex;

    font-size: 13px;
    font-weight: 500;
    color: #828282;
    // justify-content: space-between;
}

.currency-type-select {
    position: absolute;
    top: calc(100% + 8px);
    left: 20px;
    width: 80px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 10px 10px;
    padding: 8px 10px 8px 0px;
    background-color: #fff;
    z-index: 1000;
    .currency-type-select-item {
        position: relative;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 500;
        padding: 0 15px;
        .prefix {
            position: absolute;
            top: 0;
            left: 0;
            width: 8px;
            height: 100%;
        }
        &:last-child {
            margin-bottom: 0px;
        }
        &.selected {
            background: #fff3de;
            border-radius: 0px 5px 5px 0px;
            .prefix {
                background: linear-gradient(88.31deg, #ffd465 4.99%, #ffc634 84.55%, #ffb800 98.84%);
            }
        }
        &:hover {
            background: #fff3de;
            border-radius: 0px 5px 5px 0px;
            .prefix {
                background: linear-gradient(88.31deg, #ffd465 4.99%, #ffc634 84.55%, #ffb800 98.84%);
            }
        }
    }
}
.wrapper-search-btn {
    width: var(--search-button-width);
    display: flex;
    align-items: center;
    justify-content: center;
    .search-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 30px;
        padding: 0 16px;
        border-radius: var(--search-border-radius);
        font-size: 16px;
        color: var(--white);
        background-color: var(--yellow);
        text-shadow: var(--default-text-white-shadow);
        transition: var(--default-transition);

        &:hover {
            cursor: pointer;
            background-color: var(--yellow-highlight);
        }

        &:active {
            background-color: var(--yellow-highlight);
        }
    }
}
.range-slider {
    input[type='range'] {
        -webkit-appearance: none;
        margin-right: 15px;
        width: 200px;
        height: 7px;
        background: #d7f2fb;
        border-radius: 5px;
        background-image: linear-gradient(#36a9da, #36a9da);
        background-size: 70% 100%;
        background-repeat: no-repeat;
    }

    /* Input Thumb */
    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid #36a9da;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
    }

    input[type='range']::-moz-range-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid #36a9da;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
    }

    input[type='range']::-ms-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid #36a9da;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background 0.3s ease-in-out;
    }

    /* Input Track */
    input[type='range']::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    input[type='range']::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    input[type='range']::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }
}

@media (min-width: 320px) and (max-width: 576px) {
    .filter-inner {
        display: flex;
        gap: 12px;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
        height: auto;
        margin-bottom: 12px;
    }
    .filter-module {
        flex: 0 0 100%;
    }
    .search {
        height: 4rem;
        padding-left: 1.34rem;
        justify-content: center;
        align-items: center;
        .search-icon {
            width: 1.8rem;
            height: 1.8rem;
            margin-right: 1rem;
            padding: 0;
            svg {
                color: var(--primary-dark-blue);
                width: 100%;
                height: 100%;
            }
        }
        input {
            color: rgba(154, 154, 154, 0.9);
        }
        .wrapper-search-btn {
            flex: 0 0 7.6rem;
            .search-btn {
                flex: 0 0 6.2rem;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 1.6rem;
                line-height: 1.9rem;
                text-align: center;
                padding: 0;
            }
        }
    }
}
