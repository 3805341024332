@function homeBannerWidth($containerWidth) {
    $standardContainerWidth: 1300px + 50px;
    $bannerWidth: 583px;
    @return calc($bannerWidth * ($containerWidth + 50px) / $standardContainerWidth);
}

@function homeBannerHeight($containerWidth) {
    $standardContainerWidth: 1300px + 50px;

    $bannerWidth: 583px;
    $bannerHeight: 158px;

    @return calc($bannerHeight * ($containerWidth + 50px) / $standardContainerWidth);
}

@function searchModuleWidth($containerWidth) {
    $standardContainerWidth: 1300px + 50px;
    $bannerWidth: 583px;
    @return calc(($standardContainerWidth - $bannerWidth) * ($containerWidth + 50px) / $standardContainerWidth);
}

.wrapper {
    width: 100%;
    // height: 19rem;
    height: 200px;
    display: flex;
}
.search-and-hint {
    width: searchModuleWidth(var(--default-layout-width));
    // fixed top 50px
    padding-top: 50px;
    // relative padding right + left
    padding-left: 1rem;
    padding-right: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    .search {
        width: 100%;
        // height: 100%;
    }
    .hint {
        display: flex;
        width: 100%;
        // height: 30px;
        // line-height: 30px;
        align-items: center;
        justify-content: flex-start;
        vertical-align: middle;
        margin-left: 15px;
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;

        .read-more-btn {
            display: flex;
            align-items: center;
            margin-right: 10px;
        }

        .hot-search-title-text {
            display: flex;
            align-items: center;
        }

        @media (max-width: 1023px) {
            .hot-search-title {
                order: 1;
                flex: 1 0 0%;
            }

            .hot-search-key {
                order: 3;
                flex: 0 0 100%;
                padding-top: 0.6rem;
            }

            .read-more-btn {
                order: 2;
            }
        }
        span {
            // height: 30px;
            // height: 26px;
            // line-height: 26px;
            // width: -moz-fit-content;
            // width: fit-content;
            margin-right: 1.5rem;

            // &:last-child {
            //     margin-right: 0;
            // }
            &.hot-search-title {
                // flex: 0 0 8rem;
                min-width: 80px;
                span {
                    color: #3584a7;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
            &.hot-search-key {
                // height: 26px;
                display: flex;
                // flex: 0 0 calc(100% - 16rem - 3rem);
                ul {
                    height: inherit;
                    list-style: none;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                    li {
                        height: inherit;
                        // margin: 0rem 2rem 0 0;
                        line-height: 100%;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 10px;
                        }

                        a {
                            max-width: 100px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            font-family: 'Roboto';
                            font-style: normal;
                            // height: 26px;
                            font-size: 13px;
                            text-decoration: none;
                            background-color: #ffffff;
                            border-radius: var(--search-border-radius);
                            padding: 5px 12px;
                            width: max-content;
                            cursor: pointer;
                        }
                    }
                }
            }
            &.read-more-btn {
                // flex: 0 0 8rem;
                min-width: 80px;

                &.en {
                    // flex: 0 0 9rem;
                }
                a {
                    color: var(--read-more-color);
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }
}

.banner {
    // width: 45%;
    // width: 58.3rem;
    width: homeBannerWidth(var(--default-layout-width));
    display: flex;
    padding: 2rem 0;
    .inner {
        width: 58.3rem;
        height: 15.8rem;
    }
    img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.en {
    font-family: 'Roboto';
    .search-and-hint {
        .search {
            width: 100%;
            // height: 100%;
        }
        .hint {
            // margin-top: 20px;
            // height: auto;
            align-items: center;
            span {
                height: auto;
                &:last-child {
                    // margin-right: 0;
                }
                &.hot-search-title {
                    // flex: 0 0 6rem;
                    margin-right: 15px;
                    h3 {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
                &.hot-search-key {
                    ul {
                        gap: 8px;
                        li {
                            a {
                                font-family: 'Roboto';
                                font-style: normal;
                                height: auto;
                            }
                        }
                    }
                }
                &.read-more-btn {
                    // flex: 0 0 8rem;
                    font-size: 16px;
                    line-height: 16px;

                    &.en {
                        // flex: 0 0 9rem;
                    }
                    a {
                        color: var(--read-more-color);
                        font-size: 16px;
                        font-weight: 400;
                        display: flex;
                        gap: 5px;
                        justify-content: flex-start;
                        align-items: center;
                    }
                }
            }
        }
    }
}
@media (min-width: 320px) and (max-width: 575px) {
    .wrapper {
        flex-direction: column-reverse;
        // height: 19.7rem + 4.8rem;
        // height: 12.5rem + 1rem + 1.5rem + 4rem + 5rem;
        height: auto;
    }
    .search-and-hint,
    .banner {
        width: 100%;
    }
    .banner {
        padding: 0;
        padding-bottom: 0;
        justify-content: center;
        align-items: center;
        .inner {
            // width: 100%;
            margin-top: 10px;
            width: 37.5rem;
            height: 12.5rem;
        }
    }
    .search-and-hint {
        padding: 0;
        .search {
            padding: 0 1.5rem 1.5rem 1.5rem;
            height: 4rem + 1.5rem;
            margin-top: 1rem;
            .search-icon {
                width: 1.34rem;
                height: 1.34rem;
            }
            input {
                font-size: 1.6rem;
            }
        }
        .hint {
            height: 8.5rem;
            margin: 0;
            padding-left: 1.5rem;
            padding-bottom: 1.5rem;
            padding-top: 1rem;
            background-color: rgba(105, 203, 225, 0.2);
            white-space: nowrap;

            span {
                &.hot-search-title {
                    // width: 12rem;
                    // flex: 0 0 8rem;
                }
                &.hot-search-key {
                    // flex: 0 0 8rem;
                    overflow-x: scroll;
                    overflow-y: hidden;

                    &.hot-search-key::-webkit-scrollbar {
                        display: none;
                    }

                    ul {
                        flex-wrap: unset;

                        // li {
                        //     width: 10.5rem;
                        // }
                    }
                }
                &.read-more-btn {
                    // flex: 0 0 8rem;
                    &.en {
                        // flex: 0 0 9rem;
                    }
                }
            }
        }
    }
    // Scrollbar CSS
    html *::-webkit-scrollbar {
        border-radius: 0;
        width: 8px;
        height: 2px;
    }

    html *::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.15);
    }

    html *::-webkit-scrollbar-track {
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0);
    }
}
@media (min-width: 576px) and (max-width: 1023px) {
    .wrapper {
        flex-direction: column-reverse;
        // height: 19.7rem + 4.8rem;
        // height: 12.5rem + 1rem + 1.5rem + 4rem + 5rem;
        height: auto;
    }
    .search-and-hint,
    .banner {
        width: 100%;
    }
    .banner {
        padding: 0;
        padding-bottom: 0;
        justify-content: center;
        align-items: center;
        .inner {
            // width: 100%;
            margin-top: 10px;
            width: 37.5rem;
            height: 12.5rem;
        }
    }
    .search-and-hint {
        padding: 0;
        .search {
            padding: 0 1.5rem 1.5rem 1.5rem;
            height: 4rem + 1.5rem;
            margin-top: 1rem;
            .search-icon {
                width: 1.34rem;
                height: 1.34rem;
            }
            input {
                font-size: 1.6rem;
            }
        }
        .hint {
            // height: 5.3rem;
            height: 8.5rem;
            margin: 0;
            padding-left: 1.5rem;
            padding-bottom: 1.5rem;
            padding-top: 1rem;
            background-color: rgba(105, 203, 225, 0.2);
            // overflow-x: scroll;
            // overflow-y: hidden;
            white-space: nowrap;
            span {
                &.hot-search-title {
                    // width: 12rem;
                    // flex: 0 0 8rem;
                }
                &.hot-search-key {
                    // flex: 0 0 8rem;

                    ul {
                        flex-wrap: unset;

                        // li {
                        //     width: 10.5rem;
                        // }
                    }
                }
                &.read-more-btn {
                    // flex: 0 0 11rem;
                    &.en {
                        // flex: 0 0 9rem;
                    }
                }
            }
        }
    }
    // Scrollbar CSS
    html *::-webkit-scrollbar {
        border-radius: 0;
        width: 8px;
        height: 2px;
    }

    html *::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.15);
    }

    html *::-webkit-scrollbar-track {
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0);
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .search-and-hint {
        width: searchModuleWidth(960px);
        .hint {
            span {
                margin-right: 0.8rem;
                &.hot-search-key {
                    // height: 26px;
                    // flex: 0 0 calc(100% - 16rem - 1.6rem);
                }
                &:last-child {
                    // margin-right: 0;
                }
            }
        }
    }
    .banner {
        width: homeBannerWidth(960px);
    }
}
@media (min-width: 1200px) and (max-width: 1419px) {
    .search-and-hint {
        width: searchModuleWidth(1150px);
    }
    .banner {
        width: homeBannerWidth(1150px);
    }
}
@media (min-width: 1420px) and (max-width: 1535px) {
    .search-and-hint {
        width: searchModuleWidth(1300px);
    }
    .banner {
        width: homeBannerWidth(1300px);
    }
}
@media (min-width: 1536px) {
    .search-and-hint {
        width: searchModuleWidth(1300px);
    }
    .banner {
        width: homeBannerWidth(1300px);
    }
}
