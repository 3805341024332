.container {
    position: fixed;
    bottom: 50px;
    right: 200px;
    min-width: 300px;
    max-width: 300px;
    height: 350px;
    background: #fff;
    box-shadow: 3px 2px 4px rgb(0 0 0 / 40%);
    z-index: 2;
    border-radius: 10px;

    .header {
        background-color: #fdbe3b;
        min-height: 50px;
        border-radius: 10px 10px 0 0;
        padding: 5px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .list-exit {
            max-height: 18px;
            object-fit: contain;
            cursor: pointer;
        }
    }

    .search-bar-container {
        .search-form {
            width: 100%;
            position: relative;
            padding: 5px;

            .search-icon {
                position: absolute;
                -webkit-transform: translateY(50%);
                transform: translateY(50%);
                max-height: 20px;
                object-fit: contain;
                left: 15px;
            }

            .search-exit {
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                right: 20px;
                color: #ccc;
                cursor: pointer;
            }

            input {
                height: 38px;
                padding-left: 40px;
                border-radius: 5px;
                border: 1px solid #dedede;
                box-shadow: inset 0 0 5px #dedede;
                width: 100%;
            }
        }
    }

    .body {

    }
}

@media (max-width: 768px) {
    .container {
        bottom: 0;
        right: 0;
        min-width: 100vw;
        max-width: 100vw;
        z-index: 80;
    }
}