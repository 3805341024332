.wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 700;
    font-family: var(--font-family);
    background-color: var(--white);
    border: 1px solid transparent;
    user-select: none;
}
.alice-carousel {
    background-color: red;
}
.carouselImage {
    cursor: pointer;
    border-radius: 30px;
    object-fit: cover;
    @media (min-width: 320px) and (max-width: 575px) {
        border-radius: 15px;
        object-fit: unset;
    }
}
