.wrapper {
    width: 100%;
    // width: calc(100% - 74px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    // margin: auto;
    @media (max-width: 1250px) {
        width: 100%;
    }
    @media (max-width: 576px) {
        // width: 340px;
    }
}
.article {
    width: 293px;
    height: 485px;
    font-size: 13px;
    @media (min-width: 320px) and (max-width: 576px) {
        width: 290px;
    }

    &:first-child {
        margin-left: 4px;
    }

    background-color: var(--white);
    border-radius: var(--default-article-radius);
    box-shadow: var(--default-article-box-shadow);
    position: relative;
    .spacer {
        width: 100%;
        width: calc(100% - 1.5rem);
        margin-left: 1.5rem;
        height: 1.5px;
        border-bottom: 1.5px solid #e0e0e0;
        transform: matrix(1, 0, 0, 1, 0, 0);
    }
    .header {
        // height: 27.1rem;
        padding: 20px 20px 15px 20px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .thumbnail {
            width: 100%;
            height: 150px;
            margin-bottom: 15px;
            a {
                width: inherit;
                height: inherit;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .logo {
            width: 100%;
            height: 63px;
            margin-bottom: 15px;
            a {
                height: 100%;
                width: 100%;
                display: block;
                img {
                    height: 100%;
                    max-width: 100%;
                    width: auto;
                }
            }
        }
        .title {
            a > h2 {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 120%;
                height: 36px;
                width: 252.75px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .content {
        padding-top: 0;
        padding: 20px;
        .description {
            .item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                height: 1.5rem;
                margin-bottom: 15px;

                .icon-container {
                    min-width: 23px;
                }

                // truncation
                span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                svg {
                    width: 15px;
                    // margin-right: 8px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .number {
                    margin-left: 1rem;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2rem;
                    line-height: 2.3rem;
                    color: var(--primary);
                }
            }
        }
    }
    .footer {
        margin-top: 0;
        padding: 0 20px 20px 20px;

        color: var(--black);
        // item css of _tips, _helps
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
            &.view-job {
                flex: 0 0 75%;
                margin-right: 1rem;
                height: 3.9rem;
                display: flex;
                align-items: center;
                a {
                    min-width: 121px;
                    height: 36px;

                    // change color background
                    color: white;
                    background-color: #36a9da;
                    border-color: #36a9da;
                    transition: var(--default-transition);

                    &:hover {
                        background-color: #69cbe1;
                        border-color: #69cbe1;
                    }

                    span {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        padding: 0 1rem;
                    }
                    // &:hover {
                    //     border-color: var(--primary-dark-blue);
                    //     background: #36a9da;
                    // }
                }
            }
            &.view-count {
                width: 6rem;
                height: 2.82rem;
                flex: 0 0 20%;
                margin-right: 1rem;

                // style
                background: #ffffff;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.4rem;
                /* Default Shadow */

                box-shadow: var(--default-article-box-shadow);
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.view-all-btn {
    display: block;
    width: 33.6rem;
    margin: auto;
    text-align: center;
    padding-top: 35px;
    // padding-bottom: 55px;

    a {
        width: 100%;
        height: 5.2rem;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 2.9rem;
        text-align: center;
        transition: var(--default-transition);
        border-color: var(--primary);
        box-shadow: var(--default-article-box-shadow);
    }
    &.view-all-btn-style {
        a {
            background-color: var(--primary-dark-blue);
            border-color: var(--primary-dark-blue);
            color: #ffffff;
            &:hover {
                border-color: var(--primary);
                background: var(--primary);
            }
        }
    }
    &:hover {
        a {
            background-color: #69cbe1;
            border-color: #69cbe1;
        }
    }
}

@media (min-width: 320px) and (max-width: 575px) {
    .article {
        .footer {
            span {
                &.view-job {
                    a {
                        min-width: 97px;
                        height: 32px;
                        padding: unset;
                        span {
                            display: flex;
                            gap: 10px;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}
