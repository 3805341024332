.popup-popular {
    z-index: 1;
    background-color: #ffffff;
    max-height: 65vh;
    overflow: auto;
    padding: 20px 50px;
    @media (max-width: 767px) {
        padding: 10px 20px;
    }
    .wrapper {
        display: flex;
        @media (max-width: 767px) {
            flex-direction: column;
        }
        .col {
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            text-align: center;
            @media (max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
    
            .popular-item {
                display: flex;
                align-items: center;
                margin: 10px 20px;
    
                .icon-wrapper {
                    margin-right: 15px;

                    img {
                        object-fit: contain;
                        max-height: 40px;
                        min-height: 40px;
                        min-width: 50px;
                        max-width: 50px;
                    }
                }
    
                span {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 2rem;
                    line-height: 2.5rem;
                    color: var(--black-violet);
                    letter-spacing: 1px;
                    text-align: left;
                }
            }
        }
    }
}