.modal-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    z-index: 1;
    border-radius: 5px;
    margin: 10px;
    max-width: 600px;
    max-height: 80vh;
    overflow: auto;

    .modal-header {
        z-index: 1;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 0 10px 5px 0;

        button {
            background-color: transparent;
            font-size: 30px;
            font-weight: 700;
            cursor: pointer;
        }
    }
}
.modal-background {
    position: absolute;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #69cbe1), color-stop(50%, #f8c03d));
    background: -webkit-linear-gradient(top, #69cbe1 50%, #f8c03d 0);
    background: linear-gradient(180deg, #69cbe1 50%, #f8c03d 0);
    top: -5px;
    left: -5px;
    border-radius: 5px;
}
