.search,
.search-result {
    width: 500px;
}

.form-contact-container {
    @media (min-width: 320px) and (max-width: 576px) {
        margin: 0 10px;
    }
}

.form-contact {
    position: relative;
    height: 48px;
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: #ffffff;
    border-color: var(--primary-dark-blue);
    border: var(--default-input-border-style);
    transition: var(--default-transition);
    box-shadow: var(--default-article-box-shadow);
    .envelope-icon {
        position: absolute;
        left: 10px;
        top: calc(50% - 12px);
        align-items: center;
        // padding-right: 12px;
        svg {
            color: var(--primary-dark-blue);
        }
    }
    input {
        width: 100%;
        height: 100%;
        padding-right: 90px;
        padding-left: 40px;
        color: var(--black);
        font-size: 16px;
        caret-color: var(--primary);
        background-color: transparent;
        font-family: var(--font-family);
        &.lang-en {
            padding-right: 135px;
        }
    }

    .subcribe-button {
        position: absolute;
        right: 10px;
        top: calc(50% - 15px);
    }

    input::placeholder {
        color: rgba(154, 154, 154, 0.9);
    }
    input:not(:placeholder-shown) ~ .search-btn {
        color: rgba(22, 24, 35, 0.75);
    }

    &::after {
        content: '';
        position: absolute;
        top: var(--search-top-spacer);
        right: 90px;
        width: 1px;
        height: calc(var(--search-height) - var(--search-top-spacer) * 2);
        background-color: rgba(22, 24, 35, 0.12);
    }

    &:focus-within {
        border-color: rgba(22, 24, 35, 0.2);
    }
}

.search-title {
    padding: 5px 12px;
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(22, 24, 35, 0.5);
}

.clear,
.loading {
    position: absolute;
    right: calc(90px + 8px);
    top: 50%;
    transform: translateY(-50%);
    color: rgba(22, 24, 35, 0.34);
    background-color: unset;
}

.loading {
    animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
    from {
        transform: translateY(-50%) rotate(0);
    }
    to {
        transform: translateY(-50%) rotate(360deg);
    }
}

.wrapper-search-btn {
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    // &.lang-en {
    //     width: 140px;
    // }

    .submit-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 90%;
        height: 3rem;
        padding: 0 12px;
        border-radius: 50px;
        // font-size: 1.6rem;
        font-size: 1.6rem;
        // letter-spacing: 0.25rem;
        color: var(--white);
        background-color: var(--yellow);
        text-shadow: var(--default-text-white-shadow);
        transition: var(--default-transition);
        box-shadow: var(--default-button-box-shadow);

        svg {
            margin-left: 0.5rem;
        }

        @media (min-width: 320px) and (max-width: 576px) {
            // span {
            //     display: none
            // }
        }

        &.lang-en {
            font-size: 1.4rem;
        }
        &:hover {
            cursor: pointer;
            background-color: var(--yellow-highlight);
        }

        &:active {
            background-color: var(--yellow-highlight);
        }
    }
}
