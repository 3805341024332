.modal-body {
    padding: 1rem 3rem 5rem 3rem;

    .header {
        text-align: center;
        margin-bottom: 2rem;
        font-size: 3.2rem;

        @media (max-width: 767px) {
            font-size: 2.2rem;
        }
    }

    p {
        font-size: 2rem;
        @media (max-width: 767px) {
            font-size: 1.5rem;
        }
    }

    .footer {
        display: flex;
        flex-direction: column;

        .giggik-logo {
            margin-top: 5rem;
            height: auto;
            width: 150px;
            object-fit: contain;
        }
    }
}
