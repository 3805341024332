.inner-collapse {
    width: inherit;

    .inner-container {
        // background-color: rgb(254, 88, 88);
        padding: 0 25px;
    }

    .dropdown {
        display: flex;
        flex-direction: column;
        width: inherit;
        background-color: transparent;
        .header {
            height: 4rem;
            line-height: 4rem;
            display: flex;
            justify-content: space-between;
            // margin-bottom: 5px;
            // border-bottom: unset;
            // border-bottom: 2px solid var(--grey);

            span {
                flex: 0 0 calc(100% - 2rem);
                flex-shrink: unset;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                // padding-left: 18px;
                height: 4rem;
                gap: 13px;
                svg {
                    flex: 0 0 20px;
                }
                h4 {
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                }
            }
            button {
                // flex: 0 0 25px;
                align-items: center;
            }
        }
        .body {
            display: flex;
            flex-direction: column;
            transition: all ease-in 0.2s;
            overflow: hidden;
            // padding-top: 10px;
            padding-left: 18px;
            // height: 0;
            // border-bottom: 2px solid rgba(105, 203, 225, 1);
            a {
                // border-bottom: 0.5px solid var(--grey);
                // height: 2rem;
                // line-height: 2rem;
                font-size: 1.6rem;
                margin-bottom: 10px;
                color: #343131;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
        &.open {
            border-radius: unset;
            .body {
                transition: all ease-out 0.3s;
                height: auto;
            }
        }
    }

    span,
    a {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.9rem;

        justify-content: center;
        align-items: flex-start;
        padding: 1px;
        // padding-left: 8px;
        color: var(--primary);
    }
    button.arrow-icon {
        display: flex;
        align-items: center;
        background-color: transparent;
        font-size: 8px;
        color: #828282;
        height: 4rem;
    }
}
@media (min-width: 320px) and (max-width: 575px) {
    // override for footer mobile css
    .inner-collapse.optionalClass {
        .dropdown {
            .body {
                li {
                    // margin-bottom: 1rem;
                    line-height: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                }
                a {
                    line-height: 2rem;
                }
            }
        }
    }
}
