* {
    box-sizing: border-box;
}
html,
body {
    padding: 0;
    margin: 0 auto;
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 16px;
    /* font-weight: 300; */
    /* letter-spacing: 2px; */
    line-height: 40px;
    color: #404040;
}
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.dots {
    list-style-type: disc !important;
    padding-left: 1em !important;
}

ul li {
    margin: 0;
    padding: 0;
}
p {
    padding: 0 0 10px 0;
    margin: 0;
}
a {
    text-decoration: none;
    color: #f47602;
    display: inline-block;
    transition: all 0.3s ease-in-out;
}
a:hover,
a:active {
    text-decoration: none;
    color: #dd5126;
}
a:active,
a:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none;
    -moz-outline-style: none;
    text-decoration: none;
}
strong,
b {
    font-weight: 700;
}
img {
    width: 100%;
}
.bg-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 10vw;
    align-items: center;
    display: flex;
}

.bg-cream {
    background-color: #fffbea;
}

.color-red {
    color: #dd5126;
}
.color-orange {
    color: #f47602;
    font-weight: 500;
}

.color-blue {
    color: #64b5f6;
}

.color-green {
    color: #5cad5c;
}

.btn-default {
    background: #f8c03d;
    color: #fff;
    border-radius: 30px;
    font-size: 19px;
    font-weight: 700;
    padding: 10px;
    min-width: 180px;
}
.btn-default:hover {
    background: #f2b41d;
    color: #fff;
}
.btn-large {
    font-size: 28px;
    border-radius: 50px;
    padding: 10px;
    min-width: 300px;
}

input,
input:focus,
textarea,
textarea:focus {
    outline: none !important;
    box-shadow: none !important;
}
button,
button:active,
button:focus {
    outline: none !important;
    box-shadow: none !important;
}
input::-webkit-input-placeholder {
    color: #b5b5b5;
    opacity: 1;
}
input::-moz-placeholder {
    color: #b5b5b5;
    opacity: 1;
}
input::-ms-placeholder {
    color: #b5b5b5;
    opacity: 1;
}
input::placeholder {
    color: #b5b5b5;
    opacity: 1;
}

.form-group {
    position: relative;
}
.form .form-label {
    margin-bottom: 20px;
}
.form label {
    margin: 0;
    display: block;
}
textarea.form-control {
    height: 48px;
    min-height: 48px;
}
.form-control,
.form-control:focus {
    padding: 13px 0 10px;
    height: 48px;
    line-height: 16px;
    color: #0a0a14;
    border: none;
    border-bottom: 1px solid #0a0a14;
    border-radius: 0;
}
.form-control:disabled {
    background: #e2e2e2;
}

/*===============Global===============*/
h1,
h2,
h3,
h4 {
    margin: 0;
    padding: 0;
}
h1 {
    font-family: 'Noto Sans TC', serif;
    font-size: 36px;
    font-weight: 700;
}
h2 {
    font-family: 'Noto Sans TC', serif;
    font-size: 26px;
}
h3 {
    font-family: 'Noto Sans TC', serif;
    font-size: 18px;
    font-weight: 700;
}

.logo {
    width: auto;
    height: 70px;
}
#wrapper {
    width: 100%;
    position: relative;
}

/*===============Header===============*/
header {
    width: 100%;
    position: relative;
    padding: 10px 0 0;
    z-index: 2;
}
header .main-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.wave::after {
    content: '';
    width: 100%;
    left: 0;
    position: absolute;
    background-size: cover;
    z-index: -1;
}
.wave_header::after {
    /* position: fixed; */
    height: 120px;
    top: 40px;
    /* background-image: url('../../../assets/images/projectsupportstation/images/wave_header.png');
    background-position: center bottom; */
}
.wave_banner::after {
    height: 120px;
    bottom: -100px;
    background-image: url('../../../assets/images/projectsupportstation/images/top_yellow.png');
    background-position: center bottom;
}
.wave_top::after {
    height: 100px;
    top: -100px;
    background-image: url('../../../assets/images/projectsupportstation/images/wave_white_top.png');
    background-position: center top;
}
.wave_top_cream::after {
    width: 100vw;
    height: 100px;
    top: -100px;
    background-image: url('../../../assets/images/projectsupportstation/images/wave_cream_top.png');
    background-position: center top;
}
.wave_bottom::after {
    width: 100vw;
    height: 12vw;
    bottom: -7vw;
    background-image: url('../../../assets/images/projectsupportstation/images/wave_white_bottom.png');
    background-position: center bottom;
}

.main-logo {
    position: relative;
}
.logo_collapse {
    position: absolute;
    /* padding-right: 30px; */
    /* margin-top: 20px; */
}
.logo_collapse .container_logo_collapse {
    display: flex;
    flex-direction: row;
    box-shadow: 3px 5px 5px rgba(244, 118, 2, 0.2);
    border-radius: 10px;
    background: #fff;
    min-width: 75vw;
}
.logo_collapse a {
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.logo_collapse a img {
    width: auto;
    height: 50px;
}

.main-nav {
    display: block;
}
.main-nav > li {
    float: left;
    padding-right: 25px;
    font-size: 16px;
    font-weight: 700;
}
.main-nav > li a {
    color: #404040;
}
.main-nav > li a:hover {
    color: #dd5126;
}
.main-nav > li:last-child {
    padding-right: 0;
}

.main-nav.collapse:not(.show) {
    display: block;
}
.navbar-toggle {
    cursor: pointer;
    display: none;
    height: 22px;
}
.navbar-toggle span {
    background: #404040;
    border-bottom: 4px solid #404040;
    width: 27px;
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
}
.navbar-toggle span::before {
    content: '';
    border-bottom: 4px solid #404040;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 8px;
}
.navbar-toggle span::after {
    content: '';
    border-bottom: 4px solid #404040;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 4px;
}

/*===============Section===============*/
section {
    padding: 70px 0;
    position: relative;
}

.banner {
    position: relative;
    background: #fffbea;
    z-index: 1;
    background-color: #ffe48d;
    height: auto;
}

.about {
    background: #fffbea;
    padding: 60px 0 140px;
}
.about img {
    max-width: 450px;
}

.service {
    padding: 40px 0 160px;
    z-index: 1;
}
.service #nav-tab {
    border-bottom: 2px solid #f8c03d;
    gap: 50px;
}
.service .nav-link {
    border: none;
    color: #b5b5b5;
    font-weight: 700;
    padding: 0 40px 10px;
}
.service .nav-link.active {
    color: #f47602;
    position: relative;
}
.service .nav-link.active::after {
    content: '';
    border-bottom: 5px solid #f47602;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2.5px;
    left: 0;
}
.service ul li {
    margin-bottom: 20px;
}

.service .circle {
    display: grid;
    grid-template-columns: repeat(3, 33.333333%);
    margin-bottom: 2vw;
}
.service .circle .center {
    display: flex;
    flex-direction: column;
    position: relative;
}
.service .circle .center .run {
    position: relative;
}
.service .circle .center .run span {
    position: absolute;
    top: 80px;
    left: 112px;
}
.service .circle .center .run.left span {
    top: 65px;
    left: 80px;
}
.service .circle .center .run.right span {
    top: 70px;
    left: 55px;
}
.service .circle .center .run.left img {
    transform: rotate(45deg) scaleX(-1);
}
.service .circle .center .run.right img {
    transform: rotate(-60deg) scaleX(-1) translateY(-50px);
}

.service .circle .center .run img {
    width: 70%;
    margin-bottom: 10%;
}

.service .circle .arrow .image-left {
    margin-left: 50%;
    width: 25%;
    height: auto;
    /* margin: 0 auto;
    display: block; */
    margin-top: 65%;
}

.service .circle .arrow .image-right {
    margin-left: 10%;
    width: 30%;
    height: auto;
    /* margin: 0 auto;
    display: block; */
    margin-top: 65%;
}

.service .circle .arrow .image-center {
    margin-left: 32.5%;
    width: 35%;
    height: auto;
    /* margin: 0 auto;
    display: block; */
    margin-top: 45%;
}
.service .circle .arrow.left img {
    transform: rotate(130deg);
    margin-top: 50%;
}
.service .circle .arrow.right img {
    transform: rotate(-120deg);
    margin-top: calc(50% + 15px);
    /* margin-left: 20px; */
}

.benefits {
    padding: 40px 0 80px;
    z-index: 1;
}
.benefits .item {
    text-align: center;
}
.benefits .item ul {
    display: inline-block;
}
.benefits .item ul li {
    list-style-type: none;
    text-align: left;
}

.partial-contact {
    background-image: url('../../../assets/images/projectsupportstation/images/contact.png');
}

.partial-contact .btn {
    position: relative;
    z-index: 1;
}
.partial-contact .Gig_Gik {
    position: absolute;
    bottom: -30px;
    margin-left: 50px;
    height: 100px;
    width: auto;
    z-index: 0;
}

.partial-contact a {
    margin-right: 20px;
}

.faq {
    padding: 80px 0 40px;
    z-index: 1;
}

.faq .accordion-item {
    margin-bottom: 30px;
    border: none;
}
.faq .accordion-item .icon {
    width: auto;
    height: 70px;
    margin-right: 10px;
}
.faq .accordion-button {
    background: white;
    /* border-radius: 50px !important; */
    /* padding: 0 20px; */
    padding-right: 15px;
    font-weight: 300;
    font-size: 18px;
}
.faq .accordion-button::after {
    background-image: url('../../../assets/images/projectsupportstation/images/arrow.svg');
    width: 20px;
    height: 20px;
    background-size: 20px;
    background-repeat: no-repeat;
}
.faq .accordion-button.collapsed::after {
    transform: rotate(180px);
}

.accordion-button p {
    padding: 0;
}

.faq .accordion-header .accordion-button {
    background: #fffbea;
    border-radius: 50px !important;
    padding: 0 20px;
    font-weight: 500;
    font-size: 18px;
}
.faq .accordion-header .accordion-button::after {
    background-image: url('../../../assets/images/projectsupportstation/images/arrow.svg');
    width: 20px;
    height: 20px;
    background-size: 20px;
    background-repeat: no-repeat;
}
/* .faq .accordion-header .accordion-button.collapsed::after {
    transform: rotate(180px);
} */

.faq .accordion-body {
    font-size: 14px;
}

.faq .content-faq {
    display: flex;
    flex-direction: column;
    align-items: center;
}

ul.check-circle li {
    position: relative;
    padding-left: 35px;
}
ul.check-circle li::before {
    content: '';
    width: 20px;
    height: 20px;
    left: 0;
    top: 5px;
    background-image: url('../../../assets/images/projectsupportstation/images/check_circle.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
}

ul.check-circle-small li {
    position: relative;
    padding-left: 35px;
    font-size: 14px;
}

ul.check-circle-small li::before {
    content: '';
    width: 20px;
    height: 20px;
    left: 0;
    top: 1px;
    background-image: url('../../../assets/images/projectsupportstation/images/check_circle.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
}

ul.check-circle-small li.red::before {
    background-image: url('../../../assets/images/projectsupportstation/images/check_circle_red.svg');
}

ul.check-circle-small-1 li {
    position: relative;
    padding-left: 35px;
    font-size: 15px;
}

ul.check-circle-small-1 li::before {
    content: '';
    width: 20px;
    height: 20px;
    left: 0;
    top: 2px;
    background-image: url('../../../assets/images/projectsupportstation/images/check_circle.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
}

ul.check-circle-small-1 li.red::before {
    background-image: url('../../../assets/images/projectsupportstation/images/check_circle_red.svg');
}

ul.check-circle li.red::before {
    background-image: url('../../../assets/images/projectsupportstation/images/check_circle_red.svg');
}

ul.check-circle li p {
    padding: 0 0 0 0;
}

/*===============Footer===============*/
footer {
    padding: 5vw 0 30px 0;
    background: #f8c03d;
    letter-spacing: 1.5px;
    overflow: hidden;
}
footer ul {
    display: inline-block;
}
footer ul > li {
    float: left;
    padding-left: 40px;
    font-size: 16px;
    font-weight: 700;
}
footer ul > li a {
    color: #404040;
}

footer .left {
    color: #f47602;
    font-size: 14px;
    position: relative;
    z-index: 1;
}
footer .left::before {
    content: '';
    background: #fffbea;
    position: absolute;
    border-radius: 100%;
    top: -3vw;
    left: -1vw;
    width: 40vw;
    height: 40vw;
    z-index: -1;
}
footer .logo_footer {
    width: auto;
    height: 50px;
}

footer .right {
    text-align: right;
    z-index: 1;
    margin-top: 50px;
}
footer .social {
    /* display: inline-flex; */
    /* gap: 35px; */
}
footer .social img {
    width: auto;
    height: 35px;
}
footer .copyright {
    color: #fff;
    font-size: 14px;
    line-height: 0 !important;
}

.back_to_top {
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.back_to_top img {
    width: auto;
    height: 60px;
}

.container_logo {
    position: fixed;
    top: 90px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background-color: white;
    padding: 5px;
    box-shadow: 2px 2px 2px #f0eeee;
}

.collapse_container_logo {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-top: 1px solid #f1efee;
    transition-property: all;
    transition-property: 1s;
}

.collapse_container_logo img {
    width: auto;
    height: 55px;
}

.container_logo img {
    width: auto;
    height: 55px;
}

.border {
    border: 2px var(--bs-border-style) #f8c03d !important;
}

.border.red {
    border: var(--bs-border-width) var(--bs-border-style) #dd5126 !important;
}

.border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) #f8c03d !important;
}

.border-bottom-red-3 {
    border-bottom: 3px var(--bs-border-style) #dd5126 !important;
}

.color-grey {
    color: #b5b5b5;
}

.color-orange {
    color: #f47602;
    font-weight: 500;
}

.bg-yellow {
    background-color: #f8c03d;
}

.bg-red {
    background-color: #dd5126;
}

.bg-orange {
    background-color: #f47602;
}

button {
    letter-spacing: 1px;
}

.rounded-6 {
    border-radius: 20px;
}

.rounded-top-6 {
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
}

.spacing-0 {
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

.spacing-end-0 {
    padding-right: 0;
    margin-right: 0;
}

.footer-text {
    color: #f47602;
    font-size: 14px;
}

/* .footer-text a {
    color: #f47602;
    font-size: 14px;
} */

.footer-text a:hover {
    color: #f47602;
}

.footer-text a {
    font-size: 15px;
    font-weight: 500;
}

.main-nav a {
    font-size: 1.4rem;
}

.content-plan {
    height: 90%;
    transition-property: min-height;
    transition-duration: 0.5s;
    display: flex; /* Add this line to make sure it behaves as a flex container */
    flex-direction: column; /* Ensure flex items stack vertically */
    justify-content: space-between;
}

.plan {
    width: 23%;
    /* height: fit-content; */
}

/* .plan {
    width: 23%;
    position: relative;
}

.plan::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
}

.plan:hover::before {
    opacity: 0.3;
} */

.plan-red {
    background-color: #dd5126;
    transition-property: background-color;
    transition-duration: 0.5s;
}

.plan-orange {
    background-color: #f8c03d;
    transition-property: background-color;
    transition-duration: 0.5s;
}

.relative-container {
    position: relative;
}

.layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 91%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
    z-index: 1; /* Adjust the z-index value as needed */
    margin-left: 4.5%;
    transition-property: opacity;
    transition-duration: 1s;
}
/* 
.plan-blur-1 {
    position: absolute;
    background-color: black;
    opacity: 0;
    width: 22%;
    height: 94.5%;
    top: 0;
    transition-property: opacity;
    transition-duration: 1s;
}

.plan-blur-2 {
    position: absolute;
    background-color: black;
    opacity: 0;
    width: 22%;
    height: 94.5%;
    top: 0;
    transition-property: opacity;
    transition-duration: 1s;
}

.plan-blur-3 {
    position: absolute;
    background-color: black;
    opacity: 0;
    width: 22%;
    height: 94.5%;
    top: 0;
    transition-property: opacity;
    transition-duration: 1s;
}

.plan-blur-0:hover ~ .plan ~ .plan.plan-blur-3 {
    opacity: 0.3;
} */

/* .plan:hover .plan-red {
    background-color: #b9421d;
}

.plan:hover .plan-orange {
    background-color: #f2b41d;
} */

.icon-next {
    display: flex;
}

.icon-next-1 {
    display: flex;
}

.content-center {
}

.intro-content {
    align-items: center;
    display: flex;
    justify-content: center;
}

.blockquote {
    font-weight: 700;
    padding: 0 40px;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 1.5rem;
}
.blockquote:before {
    content: '';
    left: 0;
    top: 10px;
    background-image: url('../../../assets//images//projectsupportstation//images/icon-quote.png');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 25px;
    height: 18px;
    font-size: 1.5rem;
}
.blockquote::after {
    content: '';
    right: 0;
    bottom: 10px;
    background-image: url('../../../assets//images//projectsupportstation//images/icon-quote.png');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 25px;
    height: 18px;
    transform: rotate(180deg);
    font-size: 1.5rem;
}

.about .infobox {
    background: #fff;
    border: 2px solid #f8c03d;
    border-radius: 30px;
    padding: 30px;
    position: relative;
}
.about .infobox.icon_gig:before {
    top: -80px;
    right: 50px;
    width: 110px;
    height: 110px;
}
.about .award img {
    width: auto;
    height: 50px;
    transform: translateY(-10px);
}
.about .award > div {
    display: grid;
    grid-template-columns: 60px 1fr;
}
.about img.brands {
    width: auto;
    height: 70px;
    margin: 0 auto;
    margin-top: 20px;
}

.color-black {
    color: black;
}

.resource-listing {
    width: 23%;
    margin-left: 15px;
}

.banner_home {
    width: 100vw;
    height: 51vw;
    background-image: url('../../../assets/images/projectsupportstation/images/banner_home.png');
    background-position: center;
    background-size: 100vw 51vw;
    align-items: flex-end;
    display: flex;
}

.item-resource-listing {
    width: 25%;
}

.language {
    position: absolute;
    right: 30px;
    top: 20px;
}

.resource:hover .text-max-line {
    text-overflow: none;
    display: block;
    -webkit-line-clamp: none; /* number of lines to show */
    line-clamp: none;
    -webkit-box-orient: none;
}

.text-max-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.text-max-line-p p {
    padding-bottom: 5px;
}

.text-max-line-p p::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 5px;
    top: 5px;
    background-image: url('../../../assets/images/projectsupportstation/images/check_circle.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
}

/* ul.check-circle li {
    position: relative;
    padding-left: 35px;
}
ul.check-circle li::before {
    content: '';
    width: 20px;
    height: 20px;
    left: 0;
    top: 7px;
    background-image: url('../../../assets/images/projectsupportstation/images/check_circle.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
}
ul.check-circle li.red::before {
    background-image: url('../../../assets/images/projectsupportstation/images/check_circle_red.svg');
}

ul.check-circle li p {
    padding: 0 0 0 0;
} */

.run ~ .check-circle-content {
    /* opacity: 0; */
    display: block;
    transition-property: opacity;
    transition-duration: 1s;
}

/* .run:hover ~ .check-circle-content {
    display: block;
}

.run:active ~ .check-circle-content {
} */

.check-circle-content li {
    font-size: 16px;
}

.container-content-banner {
    opacity: 0;
    transition-property: all;
    transition-duration: 1s;
    max-width: 80%;
}

.content-banner {
    padding: 1.5vw;
}
.space {
    height: 1.5vw;
}

.content-banner h2 {
    font-size: 2vw;
}

.content-banner div {
    /* margin-top: 20px; */
}

.content-banner p {
    padding-top: 1vw;
    padding-bottom: 0;
    font-size: 1.4vw;
    cursor: pointer;
}

.spacing-end {
    padding-right: 20px;
}

.content-banner .color-red a {
    color: #d63808;
    font-size: 1.4vw;
    font-weight: 500;
}

.content-banner .color-blue a {
    color: #0d7dd8;
    font-size: 1.4vw;
    font-weight: 500;
}

.content-banner .color-green a {
    color: #159515;
    font-size: 1.4vw;
    font-weight: 500;
}

.content-banner .color-green a:hover {
    color: #106a10;
}

.content-banner .color-green p:hover {
    color: #106a10;
}

.content-banner .color-blue a:hover {
    color: #0f5a98;
}

.content-banner .color-blue p:hover {
    color: #0f5a98;
}

.content-banner .color-red a:hover {
    color: #7a240a;
}

.content-banner .color-red p:hover {
    color: #7a240a;
}

.icon-banner {
    width: 90%;
    transition-property: width;
    transition-duration: 0.5s;
}

/* .icon-banner:active a {
    width: 100%;
}

.icon-banner:active a .content-banner {
    opacity: 1;
} */

.video {
    height: 40vw;
}

.dropdown-banner {
    margin-bottom: 8px;
}

.intro-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-right: 20px;
}
.intro-padding-start {
    padding-left: 3rem !important;
}

.intro-padding-end {
    padding-right: 3rem !important;
}

.users_distrubutions {
    padding-left: 60px;
    padding-right: 60px;
}
.users-distrubutions-center {
}

.desktop {
    display: block;
}

.mobile {
    display: none;
}

.spacing-start-5 {
    padding-left: 3rem !important;
}

.spacing-start-0 {
    padding-left: 0;
    margin-left: 0;
}

.content-table span {
    font-size: 1.5rem;
}

.bold-500 {
    font-weight: 500;
}

.benefits_table {
    padding-left: 30px;
    padding-right: 20px;
}

.icon-table-home {
    position: absolute;
    top: -3.5vw;
    z-index: 3;
    right: 3vw;
    display: flex;
    justify-content: flex-end;
}

.fun-scope-header {
    height: 400px;
}
/*===============Custom===============*/
