.wrapper {
    width: 100%;
    // width: calc(100% - 74px);
    // display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    // margin: auto;
    @media (max-width: 1250px) {
        width: 100%;
    }
    @media (max-width: 576px) {
        width: 340px;
    }

    // overflow-x: scroll;
    // padding: 5px;
    // Scrollbar CSS
    html *::-webkit-scrollbar {
        border-radius: 0;
        width: 4px;
        height: 12px;
    }

    html *::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.15);
    }

    html *::-webkit-scrollbar-track {
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0);
    }
}

.article {
    width: 306px;
    height: 237px;
    flex: 0 0 310px;
    background-color: var(--white);
    border-radius: var(--default-article-radius);
    box-shadow: var(--default-article-box-shadow);
    padding: 20px 15px 20px 20px;

    .header {
        height: 42px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        // gap: 10px;

        span {
            // margin-right: 10px;
            &:last-child {
                margin-right: 0;
                display: flex;
                align-items: center;
            }
            &.icon {
                // width: 3.5rem;
                flex: 0 0 37px;
                margin-right: 10px;
            }

            &.title {
                line-height: 16px;
                width: 60%;
                // flex: 0 0 calc(100% - 37px - 60px - 20px);

                a {
                    display: block;
                    // margin-bottom: 5px;
                    margin-bottom: 2px;
                    h2 {
                        font-size: 16px;
                        line-height: 20px;
                        font-style: normal;
                        font-weight: 600;

                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                    &.has-badge {
                        display: flex;
                        gap: 5px;
                        .badge {
                            padding: 0px 6px;
                            height: 20px;
                            line-height: 18px;

                            width: max-content;
                            max-width: 54px;
                            font-size: 13px;
                            font-weight: 600;
                            color: var(--white);
                            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
                            transform: matrix(1, 0, 0.01, 1, 0, 0);
                            font-family: var(--font-family);
                            letter-spacing: 0.05em;

                            border-radius: 10px;
                            background-color: var(--primary);

                            &.green {
                                background-color: rgba(99, 241, 190, 1);
                            }
                            &.yellow {
                                background-color: #ffc700;
                            }
                            &.default {
                                background-color: var(--primary);
                            }
                        }
                    }
                }
                h4 {
                    font-size: 13px;
                    line-height: 20px;
                    font-style: normal;
                    font-weight: 400;
                    color: var(--sub-title-color);
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
            &.read-more {
                height: 16px;
                line-height: 16px;
                flex: 0 0 60px;
                justify-content: flex-end;
                margin-top: 2px;

                a {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    color: #828282;
                    // width: inherit;
                    width: 85px;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 5px;
                    align-items: center;
                    svg {
                        margin-left: 5px;
                    }

                    @media (min-width: 320px) and (max-width: 576px) {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .body {
        margin-top: 0.75rem;
        color: var(--black);
        // item css of _tips, _helps
        .item,
        .item-single {
            border-bottom: 1.5px solid rgba(154, 154, 154, 0.3);
            display: flex;
            gap: 1rem;
            padding-bottom: 15px;
            align-items: center;
            &:last-child {
                border-bottom: unset;
                padding-top: 10px;
            }
            div.button {
                display: flex;
                align-items: center;
                .buttonItem {
                    height: 26px;
                    width: 57px;
                    a {
                        width: 100%;
                        height: 100%;
                        min-width: unset;
                        margin-left: 0px;
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
                        border: none;
                        padding-left: 5px;
                        padding-right: 5px;
                        font-size: 16px;
                    }
                    &.en {
                        width: 55px;
                    }
                }
            }
            .thumbnail {
                width: 64px;
                height: 56px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }

            .item-title {
                width: 50%;
                .item-title-button {
                    display: flex;
                    // height: 40px;
                    align-items: center;
                }
                h3 {
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 600;
                    // font-family: 'Roboto';

                    &.en {
                        font-family: 'Roboto';
                    }

                    // truncation
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                p {
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 400;
                    color: var(--black-violet);
                    // truncation
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    &.sub-title {
                        color: var(--black-violet);
                    }
                    &.title-date {
                        font-size: 13px;
                        color: #9a9a9a;
                    }
                }
                .item-title-date {
                    color: red;
                }
            }
        }
        .item-single {
            display: block;
            width: 100%;
            .thumbnail-full-width {
                width: 100%;
                height: 83px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .bottom {
                display: flex;
                align-items: center;
                margin-top: 1.25rem;
                // margin-top: 20px;
                width: 100%;
                .item-title {
                    width: 75%;
                }
                .button {
                    float: right;
                    width: 25%;
                    display: flex;
                    justify-content: flex-end;
                    &.en {
                        width: 55px;
                        width: 25%;
                        display: flex;
                        justify-content: flex-end;
                        a {
                            min-width: 55px;
                        }
                    }
                }
            }
        }
    }
    &.en {
        font-family: 'Roboto';
        .header {
            .title {
                a {
                    position: relative;
                    h2 {
                        font-weight: 500;
                        -webkit-line-clamp: unset;
                    }
                    &.has-badge {
                        span.badge {
                            position: absolute;
                            // right: 0;
                            right: -15px;
                            transform: translate(45%, -50%);
                        }
                    }
                }
                h4 {
                    font-weight: 400;
                    -webkit-line-clamp: unset;
                    line-height: unset;
                }
            }
            .read-more {
                justify-content: flex-end;

                a {
                    svg {
                        margin-left: 5px;
                    }
                }
            }
        }
        .body {
            .item-title {
                h3 {
                    font-weight: 500;
                }
                p {
                    font-weight: 400;
                }
            }
        }
    }
}

// mobile responsive
@media (min-width: 320px) and (max-width: 576px) {
    .wrapper {
        padding-top: 0;
        flex-basis: calc(100% - 50px);
        // overflow-x: scroll;
        padding: 1.5rem 0;
    }
    // .article {
    // flex: 0 0 29rem;
    // }
}
