/*===============Responsive===============*/
@media (max-width: 1299.98px) {
    .wave_header::after {
        top: 20px;
    }
    .wave_banner::after {
        bottom: -60px;
    }

    .about {
        padding: 100px 0 140px;
    }

    .logo_collapse {
        min-width: 70vw;
    }

    .container_logo img {
        width: auto;
        height: 30px;
    }

    .collapse_container_logo img {
        width: auto;
        height: 30px;
    }
}

@media only screen and (max-width: 1199.98px) {
    .logo_collapse {
        min-width: 70vw;
    }

    .fun-scope-header {
        height: 280px;
    }

    .logo_collapse {
        min-width: 30vw;
    }

    .logo_collapse .container_logo_collapse {
        display: flex;
        flex-direction: column;
        min-width: 30vw;
    }
}

@media (min-width: 991.98px) {
    .main-logo:hover .logo_collapse {
        display: block;
    }
    /* .logo_collapse {
        min-width: 70vw;
    } */
}
@media only screen and (max-width: 991.98px) {
    .wave_header::after {
        top: 0;
    }

    .main-nav {
        background: #fff;
        position: absolute;
        padding: 10px 0;
        top: 14px;
        right: 45px;
        box-shadow: 3px 5px 5px rgba(244, 118, 2, 0.2);
    }
    .main-nav li {
        clear: both;
        line-height: normal;
        padding: 0;
        width: 100%;
    }
    .main-nav li a {
        display: block;
        padding: 10px 25px;
        font-size: 19px;
    }

    .main-nav.collapse:not(.show) {
        display: none;
    }
    .navbar-toggle {
        display: block;
    }

    .benefits .item img {
        max-width: 350px;
    }

    footer ul > li {
        padding-left: 20px;
    }
    footer .right {
        margin-top: 70px;
    }
    footer .social {
        gap: 30px;
    }
    footer .social img {
        height: 30px;
    }
    footer .left::before {
        top: -20px;
        left: -70px;
        width: 380px;
        height: 360px;
    }

    .logo_collapse {
        min-width: 60vw;
    }

    .logo_collapse a {
        margin-bottom: 5px;
    }

    .logo_collapse a img {
        width: auto;
        height: 40px;
    }

    .logo_collapse {
        min-width: 30vw;
    }

    .logo_collapse .container_logo_collapse {
        display: flex;
        flex-direction: column;
        min-width: 30vw;
    }

    .fun-scope-header {
        height: 200px;
    }
}

@media only screen and (max-width: 1000px) {
    .btn-large {
        font-size: 20px;
        border-radius: 50px;
        padding: 10px;
        min-width: 200px;
    }

    .bg-cover {
        height: auto;
    }

    .icon-table-home {
        top: -5.5vw;
        right: 2vw;
    }

    .plan {
        width: 48%;
    }

    .icon-next-1 {
        display: none;
    }

    .logo_collapse {
        min-width: 30vw;
    }

    .logo_collapse .container_logo_collapse {
        display: flex;
        flex-direction: column;
        min-width: 30vw;
    }
}

@media only screen and (max-width: 767.98px) {
    h1 {
        font-size: 30px;
    }

    .top-about-home {
        padding: 10px 0;
    }

    .service #nav-tab {
        gap: 15px;
    }
    .service .nav-link {
        padding: 0 20px 10px;
    }
    .service img {
        max-width: 450px;
    }

    .partial-contact .Gig_Gik {
        right: 0;
    }

    footer {
        line-height: 30px;
    }

    .logo_collapse {
        min-width: 30vw;
    }

    .plan {
        width: 95%;
    }

    .icon-next {
        display: none;
    }

    .icon-next-1 {
        display: none;
    }

    .logo_collapse .container_logo_collapse {
        min-width: auto;
    }

    .content-center {
        text-align: center;
    }

    .language {
        right: 22vw;
    }

    .service .circle .arrow.right img {
        margin-left: 0px;
    }

    .dropdown-banner {
        margin-bottom: 1px;
    }

    .about img.brands {
        width: 100%;
        height: auto;
    }

    .intro-content {
        padding-right: 0;
        padding-left: 20px;
    }

    .partial-contact a {
        margin-right: 0;
    }

    .service .circle .arrow img {
        width: 70%;
    }

    .intro-padding-start {
        padding-left: 0.5rem !important;
    }

    .intro-padding-end {
        padding-right: 0.5rem !important;
    }

    .users-distrubutions-center {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
    }

    .mobile .font-small {
        font-size: 15px;
    }

    .footer-text a {
        font-size: 14px;
        font-weight: normal;
    }

    .spacing-start-5 {
        padding-left: 0 !important;
    }

    .benefits_table {
        width: 90% !important;
        padding-left: 25px;
        padding-right: 10px;
    }

    .banner_home {
        height: auto;
        background-position: top;
        background-repeat: no-repeat;
        background-size: 100vw 70vw;
    }

    .container-content-banner {
        display: none;
    }

    .banner-hover {
        padding-right: 3vw;
    }

    .bg-cover {
        height: auto;
    }

    .partial-contact {
        background-image: url('../../../assets/images/projectsupportstation/images/contact-bg-mobile.png');
        background-position: bottom;
        background-size: cover;
        /* height: 40vw; */
    }

    .partial-contact .Gig_Gik {
        display: none;
    }

    /* .bg-cover {
        height: 10vw;
    } */
}
@media only screen and (max-width: 575.98px) {
    body {
        line-height: 30px;
    }
    .btn-default {
        font-size: 17px;
        min-width: 150px;
    }
    .btn-large {
        font-size: 20px;
        min-width: 200px;
    }

    .logo_collapse {
        min-width: 40vw;
    }

    .language {
        right: 15vw;
    }
    .benefits_table {
        width: 90% !important;
        padding-left: 25px;
        padding-right: 10px;
    }

    .content-banner {
        padding: 20px;
    }

    .content-banner h2 {
        font-size: 30px;
    }

    .content-banner p {
        font-size: 20px;
    }

    .content-banner .color-red a {
        color: #d63808;
        font-size: 20px;
        font-weight: 500;
    }

    .content-banner .color-blue a {
        color: #0d7dd8;
        font-size: 20px;
        font-weight: 500;
    }

    .content-banner .color-green a {
        color: #159515;
        font-size: 20px;
        font-weight: 500;
    }

    .space {
        height: 20px;
    }

    .icon-table-home {
        top: -8vw;
        right: 2vw;
    }

    .fun-scope-header {
        height: 350px;
    }
}

@media only screen and (max-width: 450px) {
    .logo_collapse {
        min-width: 60vw;
    }

    ul.check-circle-small li {
        position: relative;
        padding-left: 35px;
        font-size: 11px;
    }

    ul.check-circle-small li::before {
        content: '';
        width: 18px;
        height: 18px;
        left: 0;
        top: 1px;
        background-image: url('../../../assets/images/projectsupportstation/images/check_circle.svg');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
    }

    ul.check-circle-small li.red::before {
        background-image: url('../../../assets/images/projectsupportstation/images/check_circle_red.svg');
    }
}
